@mixin set-stars-planets-bg($alignment, $bg-color, $is-faded: false) {
	@if ($alignment == combined_right) or ($alignment == combined_left) {
		background-size: auto 100%;
		@if $alignment == combined_right {
			background-position: right;
		} @else {
			background-position: left;
		}
		@if $is-faded == faded {
			background-image: url(/images/backgrounds/#{$bg-color}_bg-#{$alignment}_aligned-faded.png);
		} @else {
			@include responsive($breakpoint-desktop, min) {
				background-image: url(/images/backgrounds/#{$bg-color}_bg-#{$alignment}_aligned.png);
			}
			@include responsive($breakpoint-desktop) {
				background-image: url(/images/backgrounds/#{$bg-color}_bg-#{$alignment}_aligned-faded.png);
			}
		}
	} @else {
		background-position: right $alignment;
		@include responsive($breakpoint-desktop, min) {
			@if $is-faded == faded {
				background-image: url(/images/backgrounds/#{$bg-color}_bg-#{$alignment}_aligned-faded.png);
			} @else {
				background-image: url(/images/backgrounds/#{$bg-color}_bg-#{$alignment}_aligned.png);
			}
			background-size: 60dvw;
		}
		@include responsive($breakpoint-desktop) {
			background-image: url(/images/backgrounds/#{$bg-color}_bg-#{$alignment}_aligned-faded.png);
			background-size: 800px;
		}
		@include responsive(50rem) {
			background-size: 110dvw;
		}
	}
	background-repeat: no-repeat;
}

$themes: light, dark;
$types: top, bottom, combined_right, combined_left;

@each $type in $types {
	@each $theme in $themes {
		.bg-stars_planets-#{$type}-#{$theme} {
			@include set-stars-planets-bg($type, $theme);
		}

		.bg-stars_planets-#{$type}-#{$theme}-faded {
			@include set-stars-planets-bg($type, $theme, faded);
		}
	}
}

.bg-fixed {
	background-attachment: fixed;
}
