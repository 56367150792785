.BookNDCProfessional {
	$border: 2px solid $color-primary;

	.existing-registration {
		border-top: $border;
		border-bottom: $border;
		padding: 2rem;

		.buttons {
			display: flex;
			gap: $spacing-sm;
			margin-top: $spacing-lg;

			.Button:last-child {
				margin-left: auto;
			}
		}

		@include responsive($breakpoint-mobile) {
			.buttons {
				flex-direction: column;
				.Button:last-child {
					margin-left: 0;
				}
			}
		}
	}

	.new-registration {
		table {
			width: 100%;
			border-top: $border;
			border-bottom: $border;

			td {
				padding: $spacing-sm;
			}

			.registrations-available {
				text-align: right;
			}
			.actions {
				width: 100px;
			}
		}

		.buttons {
			display: flex;
			justify-content: space-between;
			margin-top: $spacing-lg;

			.Button {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: $spacing-sm;

				&[disabled] {
					visibility: hidden;
				}
			}
		}
	}

	.timezone-info {
		font-size: $font-size-sm;
	}

	.cta {
		border-top: $border;
		border-bottom: $border;
		padding: 3rem;
		background: $color-blue-light;

		@include responsive($breakpoint-mobile) {
			padding: 2rem;
		}

		> p {
			margin: 0;
		}

		> .Button {
			margin-top: 2rem;
			@include responsive($breakpoint-mobile) {
				min-width: unset;
			}
		}
	}
}
