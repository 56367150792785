// Main Colours
// $color-white: #FFFFFF;
// $color-black: #13202A;

// $color-primary: #406E8E;
// $color-blue-mid: #85A4B8;
$color-blue-light: #CAD9E2;
// $color-magenta: #8E5572;

// Applying colours
$color-text: $color-black;
$color-text-light: $color-white;

$color-accent-1: $color-magenta;
$color-accent-blue: #144466;
$color-light-blue: #EBF1F4;