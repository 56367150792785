body.menu-open {
	overflow: hidden;
}

$search-bar-bg: $color-blue-dark;

@include responsive($breakpoint-tablet) {
	.desktop-only {
		display: none;
	}
	.mobile-only {
		display: unset;
	}
}

@include responsive($breakpoint-tablet, min) {
	.desktop-only {
		display: unset;
	}
	.mobile-only {
		display: none;
	}
}

// If you change any part of this, make sure you check the --navbar-height_approx variable in the general file is still accurate
$menu-padding-top: $page-spacing * 0.6;
$menu-padding-sides: $page-spacing * 0.3333;
$item-gap: $menu-padding-sides * 2;
$logo-height: $navbar-height - $menu-padding-top * 2;

.navbar {
	position: fixed;
	z-index: $index-navbar;
	width: 100%;

	padding: 0;
	height: $navbar-height;
	border-bottom: 1px solid white;

	.navbar-container {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;

		nav {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		@include responsive($breakpoint-tablet) {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-gap: $page-spacing;
		}

		.logo-container {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			padding: 1.2rem 1.5rem;

			@include responsive($breakpoint-tablet) {
				position: unset;
				transform: unset;
			}
		}

		.menu-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 100%;
			width: 100%;
			position: relative;
			@include responsive($breakpoint-tablet) {
				&:not(.show) {
					display: none;
				}
				position: fixed;
				top: 0;
				left: 0;
				background: $color-primary;
				margin-top: $navbar-height;

				flex-direction: column;
				justify-content: unset;
			}
		}

		.navbar a {
			float: left;
			font-size: 14px;
			color: white;
			text-align: center;
			padding: 14px 16px;
			text-decoration: none;
		}

		.nav-item {
			font-size: 14px;
			line-height: 29px;
			color: white;
			height: 100%;
			font-weight: 400;
			display: flex;
			align-items: center;
			cursor: pointer;
			gap: 8px;
			padding: 20px 25px;
			position: relative;
			letter-spacing: 0.65px;

			&.account-link,
			&.signup-link {
				order: 3;
				text-transform: uppercase;
				border-left: 1px solid white;
				border-right: 1px solid white;
				background-color: rgba(255, 255, 255, 0.1);
			
				&:hover {
					background-color: white;
					color: $color-blue-dark;
				}
			
				@include responsive($breakpoint-tablet) {
					color: $color-blue-dark;
					background: white;
					justify-content: center;
				}
			}

			@include responsive($breakpoint-tablet) {
				height: auto;
				font-size: 20px;
				border: 1px solid transparent;
				border-bottom-color: white;
				width: 100%;

				&:after {
					visibility: hidden;
				}
			}
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				height: 4px;
				width: 100%;
				background-color: white;
				transition: all 200ms ease;
				opacity: 0;
			}

			&:hover {
				opacity: unset;
				&:after {
					opacity: 1;
				}
			}

			@include responsive($breakpoint-tablet, min) {
				&:hover {
					.carrot {
						transform: rotate(180deg);
					}
				}
			}
		}
	
		.drop-btn {
			border: none;
			outline: none;
			background-color: inherit;
			font-family: inherit;
			margin: 0;
			display: flex;
			align-items: center;
			gap: 0.33rem;
			font-size: inherit;
			line-height: 29px;
			height: 100%;
			padding: 0px;
			font-weight: 400;
			letter-spacing: 0.65px;

			@include responsive($breakpoint-tablet) {
				width: 100%;
			}

			.carrot {
				transition: all 200ms ease;
			}
		}
		
		.dropdown {
			float: left;
			overflow: hidden;
			display: flex;
			align-items: center;

			@include responsive($breakpoint-tablet) {
				flex-direction: column;
				align-items: start;
				width: 100%;

				.drop-menu {
					width: 100%;
				}
			}
		}

		.dropdown-container {
			height: 100%;

			
			@include responsive($breakpoint-tablet, min) {
				&:hover {
					.dropdown-content {
						max-height: 100vh;
					}
				}
			}

			@include responsive($breakpoint-tablet) {
				width: 100%;

				.drop-menu.active {
					.dropdown-content {
						max-height: 100vh;

						@include responsive($breakpoint-tablet) {
							.nav-form {
								transform: translateX(0);
							}
						}
					}
				}
			}
		}
		
		.dropdown-content {
			position: absolute;
			width: 100%;
			z-index: 1;
			left: 0;
			bottom: 0;
			transform: translateY(100%);
			padding-top: 1px;
			max-height: 0;
			overflow: hidden;
			transition: all 200ms ease;

			@include responsive($breakpoint-tablet) {
				position: unset;
				transform: unset;
				padding-top: unset;
			}
			
			a.nav-link {
				font-size: 14px;
				line-height: 35px;
				float: none;
				padding: 12px 40px;
				text-decoration: none;
				display: block;
				text-align: left;
				color: white;
				background-color: $color-blue-dark;
				border-bottom: 1px solid $color-blue-light;

				@include responsive($breakpoint-tablet) {
					color: $color-blue-dark;
					background-color: $color-blue-light;
					border-bottom: 1px solid $color-blue-dark;
					padding: 16px 38px;
				}

				&:hover {
					color: $color-blue-dark;
					background-color: $color-blue-light;
					border-bottom: 1px solid $color-blue-dark;
					opacity: unset;
				}
			}
		}
	}

	.logo-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		z-index: $index-navbar-logo;

		&:after {
			content: unset;
		}

		.icon-logo {
			height: $logo-height;
			width: auto;
		}

		p {
			margin: 0;
			font-size: 1.3rem;
			font-family: $font-mirador-bold;
			font-weight: 600;
			line-height: 1;

			@include responsive($breakpoint-mobile) {
				display: none;
			}
		}
	}

	.logo-mobile {
		max-width: 40px;
		height: auto;
	}

	.hamburger-menu {
		display: flex;
        padding: 1.2rem 1.5rem;
		align-items: center;
		&.hidden {
			display: none;
		}
	}

	.hamburger-menu,
	.close-menu {
		@include active-state {
			cursor: pointer;
			color: $color-blue-mid;
		}
		@include responsive($breakpoint-tablet, min) {
			display: none;
		}
	}

	.menu-links {
		height: 100%;
		padding: 0 $menu-padding-sides;

		a {
			padding: 0px 16px;
		}

		@include responsive($breakpoint-tablet) {
			a {
				padding: 16px 38px;
			}
		}
		@include responsive($breakpoint-tablet, min) {
			flex-direction: row;
			// gap: $item-gap;
			align-items: center;
			font-size: 0.9rem;
			display: flex;
		}
		@include responsive($breakpoint-tablet) {

			display: flex;
			flex-direction: column;
			align-items: flex-start;
			// gap: $page-spacing;
			width: 100%;
			height: auto;
			padding: 0;

			font-size: 1.2rem;

			.close-menu {
				height: $navbar-height;
				padding: $menu-padding-top $menu-padding-sides;
				align-self: flex-end;
				display: flex;
				align-items: center;
			}

			.mobile-spacer {
				flex-grow: 1;
			}
		}
		@include responsive($breakpoint-mobile) {
			font-size: 1rem;
		}
	}

	$search-icon-size: 1.5rem;
	.toggle-search {
		order: 4;
		
		@include active-state {
			cursor: pointer;
		}
		svg.search-icon {
			min-width: $search-icon-size;
			min-height: $search-icon-size;
		}
		&:before {
			background: $search-bar-bg;
			z-index: -1;
			position: absolute;
			height: 100%;
			top: 0;
			right: 0;
			width: $search-icon-size + $menu-padding-sides + $item-gap * 0.5;
		}
	}
	.nav-form {
		background: $search-bar-bg;
		margin-top: 1px;
		width: 100%;
		justify-content: center;
		border-bottom: 1px solid white;
		z-index: 1100;

		.header {
			position: absolute;
			right: 0;
			height: $navbar-height;
			border-bottom: 1px solid white;
			width: 100%;
			display: none;

			@include responsive($breakpoint-tablet) {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}

			.back-button {
				height: 100%;
				cursor: pointer;
				padding: 16px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
		background-size: cover;

		&.search-form {
			transition: all 200ms ease;
			.search-button {
				padding: 0;
				text-transform: uppercase;
				cursor: pointer;

				&:disabled {
					cursor: not-allowed;
				}

				@include responsive($breakpoint-tablet, min) {
					background-color: transparent;
					color: $color-blue-dark;
					height: unset;
					border: none;

					&:hover {
						border: none;
					}
				}
			}
		}
		
		@include responsive($breakpoint-tablet) {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: -$navbar-height;
			
			background-size: cover;
			transform: translateX(100%);
			transition: all 200ms ease;

			&.search-form {
				background-image: url("/images/backgrounds/bg-mobile-search.webp");
			}
		}

		&.login-form {
			background-image: url("/images/backgrounds/bg-signin.webp");
			@include responsive($breakpoint-tablet) {
				background-image: url("/images/backgrounds/bg-signin-mobile.webp");
			}

			.nav-form-container {
				flex-direction: column;
				background-color: transparent;
				max-width: 500px;
				padding: 32px 0 48px;

				button {
					width: 100%;
					color: white;
					height: 60px;
					background-color: $color-blue-dark;
				}

				.FormControl {
					.Label {
						display: none;
					}
					width: 100%;
				}

				.forgot-password {
					color: $color-blue-dark;
					text-decoration: underline;
					font-weight: 600;
					margin-top: 8px;
					@include responsive($breakpoint-tablet) {
						color: white;
					}
				}
			}
		}

		.nav-form-container {
			background-color: $color-text-light;
			border-color: $color-text-light;
			max-width: 1065px;
			width: 100%;
			display: flex;
			align-items: center;
			padding: 0 12px;
			gap: 9px;

			.title {
				margin-bottom: 31px;
			}

			@include responsive($breakpoint-tablet) {
				flex-direction: column;
				background-color: transparent;
				padding: 0 24px;
			}
			.search-bar {
				display: flex;
				flex-direction: row;
				align-items: center;
				flex: 1;
				background-color: transparent;
				color: #6b6b6b;

				@include responsive($breakpoint-tablet) {
					background-color: $color-text-light;
					border: 1px solid $color-blue-dark;
					width: 100%;
					padding: 0 12px;
				}
				input {
					--text-color: #6b6b6b;
					margin: 0;
					margin-right: $item-gap * 0.25;
					width: 300px;
					color: var(--text-color);
					background-color: transparent;
					border: none;
					&::placeholder {
						color: var(--text-color);
						opacity: 0.5;
					}
					height: 60px;
					flex: 1;
					
					@include responsive($breakpoint-tablet, min) {
						height: unset;
					}
				}
			}

			p {
				margin: 0;
				margin-bottom: 0.5em;
				font-size: 0.85em;
			}
			button {
				vertical-align: middle;
				svg {
					width: $search-icon-size;
					height: $search-icon-size;
				}
				@include responsive($breakpoint-tablet) {
					width: 100%;
					color: white;
					height: 60px;
					background-color: $color-blue-dark;
				}
			}

			.form-input {
				background-color: $color-text-light;
				border: 1px solid $color-blue-dark;
				width: 100%;
				margin-bottom: 0;
				height: 60px;
				font-size: 14px;
			}
		}
	}

	// Desktop styling
	@include responsive($breakpoint-tablet, min) {
		.nav-form {
			display: flex;
			z-index: $index-above-navbar;
		}
		.hidden-on-desktop {
			display: none;
		}
	}

	// Mobile/tablet styling for the search form
	@include responsive($breakpoint-tablet) {
		.desktop-only {
			display: none;
		}
		.toggle-search {
			align-self: stretch;
			order: 2;
		}

		.nav-form {
			position: absolute;
			left: 0;
			align-self: stretch;
			display: flex;
			align-content: center;
			flex-wrap: wrap;
			p {
				flex-basis: 100%;
				font-size: 1em;
			}
			input {
				flex-grow: 1;
				width: auto;
				font-size: 16px;
			}
		}
	}

}

.login-link {
	order: 1;
}

.account-link {
	order: 3;
}

.search-input {
	font-size: 14px;
	line-height: 35px;
}