.SubscriptionBenefits {
	p {
		text-align: center;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			padding: 0;
			margin: $spacing-lg 0;

			display: grid;
			gap: $spacing-lg;
			grid-template-columns: auto 1fr;
			align-items: center;
		}
	}

	.explore {
		margin: 3rem 0 2rem 0;
		display: flex;
		gap: $spacing-lg;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		> .Button,
		> a.button {
			@include set-button-size(large);
			min-width: unset;
		}
	}

	.footnote {
		font-size: $font-size-sm;
		padding: 0;
		margin: 0;
	}
}
