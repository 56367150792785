.end-content-container {
	display: none;

	@media (max-width: $breakpoint-mobile), (max-width: $breakpoint-tablet) {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: auto;
		text-align: center;
		background: $color-primary;
		color: $color-white;
		width: 100%;
		bottom: 0;
		margin-bottom: 0;
		position: relative;
	}
	svg {
		margin-top: 20px;
	}
}

.end-content-message {
	@media (max-width: $breakpoint-mobile), (max-width: $breakpoint-tablet) {
		margin: 20px 0;
	}
}
.end-content-button {
	display: flex;
	justify-content: center;
	align-items: center;
	color: $color-white;

	cursor: pointer;

	&.variant-lighter {
		background-color: darken($color-blue-light, 30%);
		width: 100%;
		border: none;
		margin: 0;
		padding: 0;
	}
	&.variant-darker {
		border: 1px solid $color-white;
		background-color: $color-primary;
		min-width: 300px;
		padding: 15px;
		margin-bottom: 20px;
		@media (max-width: $breakpoint-mobile-fold) {
			min-width: 200px;
		}
	}

	&.no-icon svg.back-icon {
		display: none;
	}

	svg.back-icon {
		margin-right: 5px;
		margin-bottom: 15px;
	}
}
