.SignupPage {
	h4 {
		display: none;
	}
	.SignupViaSubscription {
		&::after {
			display: none;
		}
	}

	.Signup {
		margin: 48px;
	}

	.SignupViaSubscription {
		width: 100%;
		display: flex;
		gap: 24px;
	}
}
