.common-sleep-questions {
    width: 100%;
    .common-sleep-question {
        --border-color: #{$color-primary};
        border-top: 1px dashed var(--border-color);

        .csq-content {
            display: flex;
            flex-direction: row-reverse;
            gap: 98px;
            padding-bottom: 64px;
            margin-top: 10px;

            @include responsive(900px) {
                flex-direction: column-reverse;
                gap: 30px;
                padding-bottom: 32px;
                .article-card {
                    max-width: 100%;
                }
            }

            .article-card {
                max-width: 32.7%;

                @include responsive(900px) {
                    max-width: 100%;
                }
            }

            &>p {
                flex: 1;
            }
        }

        .csq-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            h4 {
                font-size: 40px;
                line-height: 68px;
                letter-spacing: 0;
                font-weight: 300;
            }

            @include responsive($breakpoint-mobile) {
                padding: 11px 0;
                height: unset;
                h4 {
                    font-size: 25px;
                    line-height: 28px;
                }
            }

            .expand-icon {
                width: 48px;

                @include responsive($breakpoint-mobile) {
                    width: 32px;

                    svg {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }
    }

    &:last-child {
        --border-color: #{$color-primary};
        border-bottom: 1px dashed var(--border-color);
    }
}