.faqs-page {
	.faqs-category-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	.faq-category {
		font-weight: 700;
		margin-bottom: 2em;
		> a {
			color: $color-primary;
			cursor: pointer;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	.faq-category-questions {
		list-style-type: none;
		border-top: 2px solid $color-primary;
		border-bottom: 2px solid $color-primary;
		padding: 3em 1.5em;
		margin-top: 1.5em;

		&:empty {
			&:after {
				content: 'Sorry! There are no questions in this topic';
				font-weight: 500;
				color: $color-blue-mid;
			}
		}
	}
	.question {
		margin-bottom: 1.5em;
		> a {
			color: $color-primary;
			&:hover {
				font-weight: 600;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}

		&.active {
			> a {
				font-weight: bold;
			}
		}
	}

	.faq-answer {
		background: transparentize($color-blue-light, 0.8);
		padding: 1em 1.5em;
		margin-top: 1.5em;

		p {
			margin-bottom: 1em;
			&:last-child {
				margin-bottom: 0;
			}

			a {
				color: $color-magenta;
			}
		}
	}
	@include responsive($breakpoint-desktop) {
		.faq-category-questions {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
