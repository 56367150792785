.browse-topics-page {
	$switch-view: 48rem;
	--drop-down-list-buffer: 10rem;

	> section {
		padding: $page-spacing $page-spacing * 2 $page-spacing * 2;
	}
	header {
		$breadcrumb-spacing: $page-spacing * 0.75;

		border-bottom: 2px solid $color-white;
		margin-bottom: $page-spacing * 1.5;
		padding-bottom: $page-spacing;

		display: flex;
		align-items: center;
		column-gap: $breadcrumb-spacing;
		h2 {
			margin: 0;
		}

		.breadcrumbs-container {
			flex-grow: 1;
			display: flex;
			column-gap: $breadcrumb-spacing;
			.breadcrumb-subtitle {
				display: none;
			}
		}

		a {
			display: flex;
			align-items: center;
			svg {
				margin-right: $breadcrumb-spacing * 0.5;
			}
			&:after {
				content: unset;
			}
			@include active-state {
				color: $color-blue-light;
			}
		}
	}
	.topics-container {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-column-gap: $page-spacing * 2;
	}

	.browse-topics-list {
		display: grid;
		grid-template-columns: auto;
		grid-row-gap: $page-spacing * 0.25;

		.item-name {
			--hover-color: #{$color-blue-light};
			--text-hover-color: var(--hover-color);

			font-size: 0.95rem;
			font-weight: 500;
			margin: 0;
			text-align: left;

			width: auto;
		}
	}

	.topics-dropdown-content {
		background-color: lighten($color-primary, 5%);
		animation: dropdownFadeIn 0.5s ease-in-out forwards;

		// Overrides the top parent styles to position it correctly;
		> ul.dropdown-list {
			width: 100%;
			left: 0;
			position: relative;
		}

		.dropdown-list {
			margin: 0;
			list-style-type: none;
			background-color: lighten($color-primary, 5%);
			padding: $page-spacing * 0.5 0;
			position: absolute;
			z-index: 1;
			width: calc(100% - var(--drop-down-list-buffer));
			height: 100%;
			top: 0;
			left: var(--drop-down-list-buffer);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			animation: dropdownFadeIn 0.5s ease-in-out forwards;
		}
	}
	.item-name {
		padding: 1em $page-spacing;
		margin: 0;
		@include active-state {
			background: $color-blue-mid;
			color: var(--color);
			cursor: pointer;
		}
	}
	.back {
		display: none;
	}

	@include responsive(55rem, max, height) {
		@include responsive($switch-view, min) {
			> section {
				padding-top: $page-spacing * 0.75;
			}
			header {
				padding-bottom: $page-spacing * 0.75;
				margin-bottom: $page-spacing;
			}
			.browse-topics-list,
			.topics-dropdown-content {
				grid-row-gap: 0.25rem;
				.item-name {
					font-size: 0.9rem;
				}
			}
		}
	}

	@include responsive($breakpoint-tablet) {
		--drop-down-list-buffer: 6rem;
		header {
			display: block;
			.breadcrumbs-container {
				margin-top: 2rem;
			}
		}
		.topics-container {
			grid-template-columns: 30dvw 1fr;
			grid-column-gap: $page-spacing;
		}
		.browse-topics-list .item-name {
			padding: 1em 1em;
		}
	}

	@include responsive($switch-view) {
		section {
			padding: 0;
			height: 100%;

			display: grid;
			grid-template-rows: auto 1fr;
		}
		header {
			padding: $page-spacing $page-spacing;
			margin-bottom: 0;

			// Changing the title to show which topic is currently selected, and alter the breadcrumbs accordingly
			.header-title {
				&:not(:only-child) {
					display: none;
				}
			}
			.breadcrumbs-container {
				margin-top: 0;
				display: flex;
				column-gap: $page-spacing * 0.25;
				row-gap: $page-spacing * 0.25;
				flex-wrap: wrap;
				font-size: 0.9rem;
				align-items: center;
				.breadcrumb-subtitle {
					font-weight: 500;
					display: block;
				}
				a {
					&:last-child {
						font-family: $font-display-bold-family;
						font-weight: $font-display-bold-weight;
						letter-spacing: $font-body-bold-spacing;
						font-size: $font-size-xxl;
						flex-grow: 1;
						flex-basis: 100%;
						margin-top: $page-spacing * 0.5;

						svg {
							display: none;
						}
					}
					&:not(:last-child) {
						svg {
							order: 10;
							margin-right: 0;
							margin-left: 0.25em;
							width: 1.3em;
							height: 1.3em;
						}
					}
				}
			}
		}
		.topics-container {
			display: block;
			background: $color-white;
			color: $color-text-dark;
			padding: $page-spacing $page-spacing * 2;
		}

		.browse-topics-list {
			&:only-child {
				display: block;
			}
			display: none;
		}

		.topics-dropdown-content {
			background: none;
			.dropdown-list {
				background: none;
				box-shadow: none;
				padding: 0;
				position: initial;
				width: 100%;

				&.inactive {
					> .item-name:not(.active),
					> .back {
						display: none;
					}
				}
			}
		}

		.browse-topics-list .item-name,
		.item-name {
			display: block;
			width: 100%;

			border: 2px solid $color-primary;
			color: $color-primary;
			padding: 1.5em 2em;
			text-align: center;
			font-size: 1.1rem;
			font-weight: 600;
			margin-bottom: $page-spacing;

			&.active {
				padding: 0;
				border: none;
				font-size: 0;
				margin: 0;
			}
			@include active-state {
				background: unset;
				border-color: $color-blue-mid;
				color: $color-blue-mid;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.back {
			width: max-content;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: $spacing-sm;
			@include active-state {
				cursor: pointer;
				svg {
					@include animate-pulse;
				}
			}
		}
	}

	@include responsive($breakpoint-mobile) {
		.topics-container {
			padding: $page-spacing;
		}

		.browse-topics-list .item-name,
		.item-name {
			font-size: 0.9rem;
		}
	}
}

@keyframes dropdownFadeIn {
	0% {
		opacity: 0;
		transform: translateY(-20px) scale(0.95);
	}
	100% {
		opacity: 1;
		transform: translateY(0) scale(1);
	}
}

@keyframes dropdownFadeOut {
	0% {
		opacity: 1;
		transform: translateY(0) scale(1);
	}
	100% {
		opacity: 0;
		transform: translateY(-20px) scale(0.95);
	}
}
