#audio-page {
	--content-max-width: 45rem;

	section {
		padding-bottom: 0;
		&:last-child {
			padding-bottom: $page-spacing * 3;
		}
	}

	.icon-heading {
		display: flex;
		align-items: center;

		.icon {
			margin-left: 8px;
			display: flex;
		}
	}

	iframe {
		margin: 2em auto;
		width: auto;
		height: 500px;
		border: none;
		@include responsive($breakpoint-mobile) {
			width: 100%;
			height: 338px;
		}
	}
	.subtext-small-italic {
		margin-top: $page-spacing;
		p {
			font-size: 0.75em;
			font-style: italic;
			font-weight: 600;
			margin-bottom: 0;
		}
	}

	.audio {
		.content-block {

			max-width: var(--content-max-width);
		}
		&.dual-content-section {
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}
