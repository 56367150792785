.article-page {
	.finished-section {
		padding: $page-spacing * 1.5;
		display: flex;
		flex-direction: column;
		row-gap: $page-spacing;
		align-items: center;

		.finished-text {
			margin: 0;
			font-family: $font-display-bold-family;
			font-weight: $font-display-bold-weight;
			letter-spacing: $font-body-bold-spacing;
			font-size: $font-size-xl;
			line-height: 1em;
		}
	}
	.social-share-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		color: $color-white;

		.subtext {
			font-size: $font-size-sm;
		}

		.icons-container {
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			@include responsive($breakpoint-tablet) {
				gap: 20px;
			}
			button,
			a {
				background: none;
				border: none;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				scale: 0.8;
				@include responsive($breakpoint-tablet) {
					scale: 1;
				}
				&:hover {
					background-color: $color-blue-mid;
				}
				.tabler-icon {
					width: unset;
					height: unset;
					&:hover {
						background-color: $color-blue-mid;
					}
				}
			}
		}
	}

	--section-padding: #{$page-spacing * 3} #{$page-spacing * 3} #{$page-spacing * 2};

	@include responsive($breakpoint-tablet) {
		--section-padding: #{$page-spacing * 2.5} #{$page-spacing * 2} #{$page-spacing * 2};
	}
	@include responsive($breakpoint-mobile) {
		--section-padding: #{$page-spacing * 1.5} #{$page-spacing} #{$page-spacing * 1.5};
	}

	.next-article-section {
		padding: var(--section-padding);

		display: flex;
		flex-direction: column;
		row-gap: $page-spacing * 1.5;
		align-items: center;

		> * {
			max-width: 50rem;
		}

		.next-article-location {
			font-family: $font-body-normal-family;
			letter-spacing: unset;
			font-size: 1.2rem;
			width: 100%;
			margin: 0;
			line-height: 1.7em;
			.category-name {
				color: $color-blue-mid;
			}
		}

		.article {
			.article-title {
				font-family: $font-display-bold-family;
				font-weight: $font-display-bold-weight;
				letter-spacing: $font-body-bold-spacing;

				font-size: 2.5rem;
				margin-top: 0;
				margin-bottom: $page-spacing * 0.75;
				line-height: 1.25em;
			}
			img {
				display: none;
			}

			.markdown {
				position: relative;
				&:after {
					content: '';
					position: absolute;
					display: block;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(
						180deg,
						rgba(255, 255, 255, 0) 0%,
						rgba(255, 255, 255, 0) 50%,
						rgba(255, 255, 255, 1) 100%
					);
				}

				h1,
				h2,
				h3,
				h4,
				h5 {
					display: none;
				}
			}
		}

		.btn-keep-reading {
			button {
				padding: 1em 3em;
			}
			&:after {
				content: unset;
			}
		}
	}

	.other-articles-section {
		padding: var(--section-padding);

		.other-articles-header {
			border-bottom: 2px solid $color-text-light;
			padding-bottom: $page-spacing * 2;
			max-width: 1200px;
			margin: 0 auto $page-spacing * 0.5;
			text-align: center;
			h3 {
				margin: 0;
			}
		}

		.article-list-container {
			.results-list {
				li.pinned {
					background: transparentize($color-text-light, 0.95);
				}
				.article-title {
					@include active-state {
						color: transparentize($color-text-light, 0.3);
					}
				}
				.actions {
					button {
						--color: #{$color-text-light};
					}
				}
			}
		}

		.view-full-list-btn {
			margin: $page-spacing * 3 auto 0;
			display: block;
			max-width: 22rem;
			padding: 1.2em 2em;
		}

		@include responsive($breakpoint-tablet) {
			.article-list-container {
				padding: 0;
			}
		}

		@include responsive($article-list-breakpoint) {
			padding-left: 0;
			padding-right: 0;
			.other-articles-header {
				padding-left: $page-spacing;
				padding-right: $page-spacing;
			}
		}
	}
}
