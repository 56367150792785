.LoginPage {
	min-height: 100dvh;
	section {
		min-height: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: $page-spacing $page-spacing * 2;
	}
	h1 {
		margin: 0;
		padding-bottom: $spacing-xxxl;
		border-bottom: 1px solid $color-blue-mid;
	}

	h4 {
		margin: $spacing-xxxl 0;
	}

	.login-and-subs-container {
		display: grid;
		grid-template-columns: minmax(220px, 360px) auto 1fr;
		gap: $spacing-xxxl;
	}

	.divider {
		width: 1px;
		height: 100%;
		background: $color-blue-mid;
	}

	.LoginForm {
		.Form {
			display: flex;
			flex-direction: column;
			gap: $spacing-xl;
			align-items: flex-start;

			.FormControl {
				width: 100%;
			}

			label {
				display: none;
			}
			input {
				color: $color-secondary;
				border-bottom: 2px solid $color-blue-mid;

				&::placeholder {
					color: $color-blue-mid;
				}
			}

			.Button[type='submit'] {
				@include set-button-color($color-secondary);
				@include set-button-type(outline);
				--text-color: $color-secondary;
			}

			.forgot {
				margin-top: $spacing-lg;
			}
		}
	}

	.SubscriptionBenefits .explore a.login {
		display: none;
	}

	.SignupViaSubscription {
		max-width: 640px;
	}

	@include responsive(calc($breakpoint-mobile + 180px)) {
		$row-spacing: $page-spacing * 1.5;

		section {
			padding: $page-spacing * 2 $page-spacing * 2 $page-spacing * 4;
		}

		.SubscriptionBenefits .explore a.login {
			display: unset;
		}

		.login-and-subs-container {
			display: flex;
			flex-direction: column-reverse;
			gap: $row-spacing;

			.divider {
				width: 100%;
				background: $color-text-light;
				height: 2px;
			}

			.LoginForm {
				.Form {
					align-items: center;
				}
			}
		}

		h1 {
			border-bottom: 2px solid $color-text-light;
			margin-bottom: $row-spacing;
		}
		h4 {
			margin-top: 0;
		}
	}

	@include responsive($breakpoint-tablet) {
	}
}
