			
.journal-logo {
    transition: all 200ms ease;
    opacity: 0.7;
    width: auto;
    height: auto;

    &:hover {
        opacity: 1;
    }
}

.journal-logo__container {
    position: relative;

    @include responsive($breakpoint-mobile) {
        width: 100%;
    }

    .expand-icon {
        display: none;
        margin-right: 18px;
    }

    .journal-logo-image {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 76px;
    }

    .journal-logo__tooltip {
        visibility: hidden;
        position: absolute;
        width: clamp(330px, 100%, 330px);
        padding: 20px;
        border-radius: 10px;
        background-color: $color-accent-blue;
        color: white;
        z-index: 1;
        bottom: 120%;
        left: 50%;
        /* center the tooltip text box*/
        transform: translate(-50%);
        /* animation */
        opacity: 0;
        transition: opacity 1s;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .journal-logo__tooltip-title {
            font-size: 14px;
            font-style: italic;

            &:before,
            &:after {
                content: '"';
            }
        }

        .journal-logo__tooltip-content {
            font-size: 10px;
        }
    }

    @include responsive($breakpoint-mobile) {
        border-bottom: 1px dashed white;
        padding: 7px 0 6px 4px;

        .journal-logo__tooltip{
            position: relative;
            visibility: visible;
            display: none;
            opacity: 1;
            background-color: transparent;
            width: 100%;
            padding: 0 0 16px;
            margin-top: 27px;
            bottom: 0;
            line-height: 20px;
        }

        .journal-logo__tooltip-content {
            margin-top: 16px;
            line-height: 17px;
        }

        &.active {
            .journal-logo__tooltip{
                display: block;
            }
        }

        .expand-icon {
            display: block;
        }
    }
    
    @include responsive($breakpoint-mobile, min) {
        .journal-logo__tooltip::after{
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%);
            border: 10px solid;
            border-color:  $color-accent-blue transparent transparent transparent;
        }
    }

    &:hover .journal-logo__tooltip{
        visibility: visible;
        opacity: 1;
    }
}