.articleContainer {
	$horizontal-padding: $page-spacing;
	padding: calc(var(--navbar-height_approx) + #{$page-spacing}) $horizontal-padding $page-spacing * 2;

	// -------------------------------------
	// Layout
	// -------------------------------------
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	grid-template-areas:
		'breadcrumbs breadcrumbs breadcrumbs'
		'left-content main-content right-content';
	row-gap: $page-spacing * 2;
	column-gap: $horizontal-padding * 2;

	.left-side-content,
	.right-side-content {
		position: sticky;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		top: 0;

		> * {
			position: sticky;
			bottom: $horizontal-padding;
		}
	}

	.left-side-content {
		grid-area: left-content;
	}

	.main-content {
		grid-area: main-content;

		max-width: 55rem;
		margin: 0 auto;
	}

	.right-side-content {
		grid-area: right-content;
	}

	// -------------------------------------
	// Breadcrumbs
	// -------------------------------------
	.breadcrumbs {
		grid-area: breadcrumbs;
		$item-spacing: $page-spacing * 0.25;
		margin: 0;
		padding: 0;
		list-style-type: none;

		display: flex;
		column-gap: $item-spacing;
		align-items: center;
		flex-wrap: wrap;
		row-gap: $page-spacing * 0.25;

		color: $color-blue-mid;

		li {
			margin: 0;
			display: flex;
			column-gap: $item-spacing;
			align-items: center;

			svg {
				--svg-size: 1.2rem;
				height: var(--svg-size);
				width: var(--svg-size);
			}

			a {
				line-height: 1em;
			}
		}
	}

	// -------------------------------------
	// Article Title + Additional Info
	// -------------------------------------
	.additional-article-info {
		color: $color-blue-mid;
		margin-bottom: $page-spacing * 0.5;

		p {
			margin: 0;
		}

		.essential-reading {
			display: flex;
			align-items: center;
			gap: 0.5em;
		}
	}

	// Article content is located lower in the file

	// -------------------------------------
	// Article Navigation Options
	// -------------------------------------
	.article-navigation-options_container {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		row-gap: 1.25rem;

		button {
			background: unset;
			padding: 0;
			border: none;
			column-gap: 1rem;
			font-size: 0.75rem;

			color: $color-primary;

			display: flex;
			align-items: center;

			svg {
				--icon-size: 1.75em;
				width: var(--icon-size);
				height: var(--icon-size);
			}

			@include active-state {
				cursor: pointer;
				color: $color-blue-mid;
			}
		}

		a:after {
			content: unset;
		}

		@include responsive($breakpoint-tablet, min) {
			.mobile-trigger-button {
				display: none;
			}
		}
	}

	// -------------------------------------
	// Progress Tracker
	// -------------------------------------

	.progress-tracker_button {
		background: unset;
		padding: 0;
		border: none;

		color: $color-primary;
		text-align: left;
		font-size: 1rem;

		span {
			display: block;
			&.subtext {
				font-size: 0.75em;
			}
			&.hidden-until-hover {
				opacity: 0;

				position: absolute;
				top: 100%;
			}
			&.article-count {
				margin: 0.5em 0;
			}
		}
		@include active-state {
			.hidden-until-hover {
				opacity: 0.5;
			}
		}
	}

	.progress-tracker_list {
		margin-bottom: $page-spacing;
		bottom: 8rem;
		max-height: calc(100dvh - 17rem);
		overflow: auto;

		$border: 2px solid $color-primary;
		border-top: $border;
		border-bottom: $border;
		padding: 0.5rem 0;

		a {
			display: block;
			font-size: 0.8rem;
			padding: 0.75em;
			font-weight: 500;
			line-height: 1.6em;
			&.already-read {
				opacity: 0.6;

				.read-indicator {
					font-size: 0.8em;
					display: flex;
					align-items: center;
					gap: 0.5em;
					margin-top: 0.5em;

					svg {
						$svg-size: 1.5em;
						width: $svg-size;
						height: $svg-size;
					}
				}
			}
			&:after {
				content: unset;
			}
			@include active-state {
				background: transparentize($color-primary, 0.9);
			}
		}
	}

	// -------------------------------------
	// Responsive styles
	// -------------------------------------
	@include responsive(85rem) {
		grid-column-gap: $page-spacing;
		.main-content {
			max-width: 45rem;
		}
	}
	@include responsive($breakpoint-tablet) {
		grid-template-columns: 0 auto 0;
		grid-column-gap: 0;
		.left-side-content {
			display: none;
		}
		.article-navigation-options_container {
			position: absolute;
			bottom: 0;
			right: 0;

			max-width: calc(100vw - ($horizontal-padding * 2));
			row-gap: 0;

			&:not(.open) {
				> *:not(.mobile-trigger-button) {
					display: none;
				}
			}

			a {
				width: 100%;
			}

			&.open {
				width: 20rem;
				button:not(.mobile-trigger-button) {
					background: $color-primary;
					color: white;
					width: 100%;
					justify-content: right;
					padding: 2em 2em;
					font-size: 0.9rem;
				}
				.mobile-trigger-button {
					background: $color-primary;
					color: $color-text-light;
				}

				button {
					@include active-state {
						background: $color-blue-light;
						color: $color-primary;
					}
				}
			}

			.mobile-trigger-button {
				$button-size: 4rem;
				background: transparentize($color-primary, 0.6);
				width: $button-size;
				height: $button-size;
				justify-content: center;
				svg {
					--icon-size: 60%;
				}

				@include active-state {
					background: $color-primary;
					color: $color-text-light;
				}
			}
		}
	}
	@include responsive($breakpoint-mobile) {
	}
}

// -------------------------------------
// Article Content
// -------------------------------------
.ArticleHtml {
	.markdown {
		img {
			border: 0.5rem solid $color-primary;
		}

		blockquote {
			background: transparentize($color-primary, 0.9);
			margin: 1.75em 0;
			padding: 1.5em;
		}

		h2 {
			margin: 2.5em 0 1.25em;
		}

		iframe {
			margin: 4em auto;

			@include responsive(682px) {
				height: 49dvw;
			}

			@include responsive($breakpoint-mobile) {
				margin: 2.5em auto;
			}
		}

		p,
		a {
			word-break: break-word;
		}
		> :last-child {
			margin-bottom: 0;
		}

		a {
			color: $color-accent-1;
			&:after {
				border-color: $color-accent-1;
			}
		}

		ul {
			@include responsive($breakpoint-tablet) {
				padding: 0 2em 0 1.2em;
			}
		}

		a.rollover {
			color: $color-primary;
			text-decoration: underline dashed $color-primary;
			text-underline-offset: 10px;

			&:hover,
			&:focus,
			&:active {
				&:after {
					display: none;
				}
			}
		}
		.table-wrapper {
			@include scrollbar(5px, $color-primary);
		}
	}
}
