.Button {
    &.solid {
        color: $color-blue-dark;
        background-color: $color-white;
        padding: 0 55px;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.28px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        height: 80px;
        border: none;
        transition: all 400ms ease;
    
        &.outline {
            border: 1px solid $color-white;
        }
    
        &.outline:hover {
            border: 1px solid $color-white;
        }
    
        &.reverse:hover {
            color: $color-white;
            background-color: $color-blue-dark;
            box-shadow: none;
            transform: none;
    
            img {
                filter: brightness(0) invert(1);
            }
        }
    
        &:hover {
            color: $color-blue-dark;
            background-color: $color-white;
            box-shadow: 0px 0px 7px rgba(0,0,0,0.3);
            transform: scale(1.05);
        }
    
        svg {
            width: 34px;
            height: 34px;
            path {
                fill: $color-blue-dark
            }
        }
        
        &.icon {
            padding: 0 30px;
            svg {
                path {
                    fill: inherit;
                }
            }
        }
    
        &.dark {
            color: $color-white;
            background-color: $color-blue-dark;
    
            &.reverse:hover {
                color: $color-blue-dark;
                background-color: $color-white;
                box-shadow: none;
                transform: none;
            }
    
            &.outline {
                border: 1px solid $color-blue-dark;
            }
        
            &.outline:hover {
                border: 1px solid $color-blue-dark;
            }
        }
    }
}