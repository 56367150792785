.category-page {
	$mobile-subtext-color: $color-blue-mid;
	$mobile-subtext-size: 0.8rem;

	.category-header {
		background-size: 40dvw;
		background-position: top right;

		padding: $page-spacing * 0.5 $page-spacing;
		display: grid;
		grid-column-gap: $page-spacing * 2;
		grid-template-columns: 1fr auto;
		align-items: center;

		.explore-more-btn {
			background-color: $color-primary;
		}

		.subtitle {
			margin: 0;
			font-size: 0.8rem;
		}

		h3 {
			margin-top: 0.5em;
			margin-bottom: 0.75em;
		}

		.filter-container {
			display: flex;
			align-items: center;
			gap: $page-spacing * 0.5;
			button {
				padding: 0.5em 1.5em;
				font-size: 0.7rem;
				border-width: 1px;
			}
		}
	}
	.number-of-results {
		padding: $page-spacing;
	}
	.pagination {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 6rem;
		button {
			@include active-state {
				@include animate-pulse;
			}
			color: $color-brand-2;
		}
		.current-page {
			font-size: $font-size-md;
			font-weight: $font-body-normal-weight;
		}
		.invisible {
			visibility: hidden;
		}
		@include responsive($article-list-breakpoint) {
			margin-bottom: $page-spacing;
			gap: 3rem;
		}
		@include responsive($breakpoint-mobile) {
			font-size: $font-size-sm;
			.current-page {
				font-size: $font-size-sm;
			}
		}
	}

	// Mobile styling - slightly larger than the normal breakpoint, as that is what the content needed
	@include responsive($article-list-breakpoint) {
		.category-header {
			border-bottom: 2px solid white;
		}
	}

	@include responsive($breakpoint-mobile) {
		.category-header {
			background-image: unset;
			display: block;
			padding: 0;

			$horizontal-spacing: $page-spacing * 0.5;
			p,
			h3 {
				padding-left: $horizontal-spacing;
				padding-right: $horizontal-spacing;
			}

			.filter-container {
				background: $color-blue-mid;
				padding: $page-spacing * 0.4 $horizontal-spacing;
			}
			.subtitle {
				font-size: 0.7rem;
			}
			h3 {
				font-size: 1.8rem;
				margin-bottom: 0.5em;
			}

			.explore-more-btn {
				display: none;
			}
		}
	}
}
