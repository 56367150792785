.journey-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 17px;

    @include responsive($breakpoint-desktop) {
        gap: 7px;
    }

    @media (max-width: $breakpoint-mobile) {
        flex-direction: row;
        gap: 16px;
    }

    &:hover {
        &.Slide-Up,
        &.Galaxy {
            .thumbnail {
                .main-image  {
                    transform: translateY(0px);
                }

                .galaxy-effect {
                    opacity: 1;
                }
            }
        }

        &.Card {
            .thumbnail {
                .main-image  {
                    transform: translateY(24px) translateX(23px) rotate(-4deg);

                    @include responsive($breakpoint-mobile) {
                        transform: translateY(18px) translateX(19px) rotate(-4deg);
                    }
                }

                .effect-1:before {
                    transform: translateY(23px) translateX(20px) rotate(-0.5deg);

                    @include responsive($breakpoint-mobile) {
                        transform: translateY(15px) translateX(16px) rotate(-0.5deg);
                    }
                }
    
                .effect-1:after {
                    transform: translateY(20px) translateX(19px) rotate(2.5deg);

                    @include responsive($breakpoint-mobile) {
                        transform: translateY(12px) translateX(15px) rotate(2.5deg);
                    }
                }
    
                .effect-2:before {
                    transform: translateY(16px) translateX(18px) rotate(5.5deg);

                    @include responsive($breakpoint-mobile) {
                        transform: translateY(8px) translateX(14px) rotate(5.5deg);
                    }
                }
    
                .effect-2:after {
                    transform: translateY(12px) translateX(17px) rotate(8.5deg);

                    @include responsive($breakpoint-mobile) {
                        transform: translateY(4px) translateX(13px) rotate(8.5deg);
                    }
                }
            }           
        }

        &.Slide-Left {
            .thumbnail {
                .main-image  {
                    transform: translateX(-10px);
                }
            }
        }
    }

    &.Card {
        .thumbnail {
            .main-image {
                transform-origin: bottom center;
                position: absolute;
                width: 152px;
                height: 214px;
                transform: translateY(40px) translateX(18px);
                z-index: 10;

                @include responsive($breakpoint-mobile) {
                    width: 97px;
                    height: 137px;
                    transform: translateY(22px) translateX(14px);
                }
            }

            .effect:before,
            .effect:after {
                content: '';
                position: absolute;
                width: 152px;
                height: 214px;
                border-radius: 13px;
                background-color: $color-blue-dark;
                opacity: 0.15;
                box-shadow: 0px 3px 3px rgba(0,0,0,0.25);
                transform-origin: bottom center;
                transition: all 200ms ease;

                @include responsive($breakpoint-mobile) {
                    width: 97px;
                    height: 137px;
                    border-radius: 8px;
                }
            }

            .effect-1:before {
                transform: translateY(37px) translateX(21px);
                z-index: 9;

                @include responsive($breakpoint-mobile) {
                    transform: translateY(19px) translateX(17px);
                }
            }

            .effect-1:after {
                transform: translateY(34px) translateX(24px);
                z-index: 8;

                @include responsive($breakpoint-mobile) {
                    transform: translateY(16px) translateX(20px);
                }
            }

            .effect-2:before {
                transform: translateY(31px) translateX(27px);
                z-index: 7;

                @include responsive($breakpoint-mobile) {
                    transform: translateY(13px) translateX(23px);
                }
            }

            .effect-2:after {
                transform: translateY(28px) translateX(30px);
                z-index: 6;

                @include responsive($breakpoint-mobile) {
                    transform: translateY(10px) translateX(26px);
                }
            }
        }
    }

    &.Card,
    &.Slide-Up,
    &.Galaxy {
        .thumbnail {
            mask-image: url('/images/journey/bottom-mask.png');
            mask-position: bottom;
            mask-size: 100%;
        }
    }

    &.Slide-Up,
    &.Galaxy {
        .thumbnail {
            .main-image  {
                transform: translateY(8px);
                width: 100%;
            }
        }
    }

    &.Galaxy {
        .thumbnail {
            .galaxy-effect {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: auto;
                z-index: 9;
                opacity: 0;
                transition: all 200ms ease;
            }
        }
    }

    &.Slide-Left {
        .thumbnail  {
            mask-image: url('/images/journey/right-mask.png');
            mask-position: right bottom;
            align-items: end;
            @include responsive($breakpoint-mobile) {
                mask-size: 150%;
            }
            .main-image {
                transform: translateX(10px);
                height: 200px;

                @include responsive($breakpoint-mobile) {
                    height: 128px;
                }
            }
        }
    }

    .thumbnail {
        width: 200px;
        height: 220px;
        overflow: hidden;
        position: relative;
        display: flex;

        @include responsive($breakpoint-mobile) {
            width: 128px;
            height: 134px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            aspect-ratio: 1;
            border-radius: 50%;
            background: $color-blue-light;
            bottom: 0;
            left: 0;
            z-index: -1;
        }

        .main-image {
            transition: all 0.2s ease;
            object-fit: contain;
            z-index: 20;
            height: auto;
            width: auto;
        }
    }

    .content {
        display: flex;
        gap: 10px;
        flex-grow: 1;
        text-align: start;
    
        @media (max-width: $breakpoint-mobile) {
            flex-direction: column;
            flex: 1;
            gap: 0;
        }
    }
}