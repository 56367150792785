.featured-article-container {
	--article-count: 3;
	@include responsive($breakpoint-large-desktop, min) {
		--article-count: 3;
	}
	@include responsive($breakpoint-tablet) {
		--article-count: 2;
	}
	@include responsive($breakpoint-mobile) {
		--article-count: 1;
	}

	text-align: left;
    width: 100%;

	h2 {
		margin-bottom: 3em;
		position: relative;
	}

	display: flex;
    flex-direction: column;
	justify-content: center;
	padding: 0;

	position: relative;

    .carousel-controls {
        $gap-space: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $gap-space;
        margin-top: 2rem;
		visibility: hidden;

		@include responsive($breakpoint-tablet) {
			visibility: visible;
		}
        .carousel-button {
            background-color: transparent;
            color: $color-white;
            border: none;
            cursor: pointer;
            transition: background-color 0.3s ease;

            img {
                display: block;
                transition: transform 0.3s ease;
            }

            &:hover {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }

    $column-spacing: 8px;

	.featured-article-carousel {
		display: flex;
		overflow-x: auto;
		scroll-behavior: smooth;
		-ms-overflow-style: none;
		scrollbar-width: none;
		position: relative;
        width: 100%;
		gap: $column-spacing;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.featured-article-item {
		flex: 0 0
			calc((100% - (#{$column-spacing} * (var(--article-count) - 1))) / var(--article-count));
		flex-direction: column;
		gap: $column-spacing;
		display: flex;

		> * {
			margin: 0;
		}

        .article-card {
            height: 100%;
        }
	}
}
