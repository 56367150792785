.PodcastLink {
	$border: 2px solid $color-primary;
	
	p {
		> a {
			word-break: break-all;
		}
	}

	.cta {
		border-top: $border;
		border-bottom: $border;
		padding: 3rem;
		background: $color-blue-light;

		@include responsive($breakpoint-mobile) {
			padding: 2rem;
		}

		> p {
			margin: 0;
		}

		> .Button {
			margin-top: 2rem;
			@include responsive($breakpoint-mobile) {
				min-width: unset;
			}
		}
	}
}
