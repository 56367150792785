.UserProfile {
	.AccountDetailsForm {
		margin-top: 3rem;

		> form {
			display: grid;
			gap: 3rem;
			max-width: 860px;
			margin-bottom: 3rem;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-areas:
				'fullName email confirmEmail'
				'displayName password confirmPassword'
				'controls controls controls';

			.FormControl {
				max-width: 256px;
			}

			.controls {
				grid-area: controls;
				display: flex;
				align-items: center;
				gap: $spacing-sm;
			}

			[data-control-name='fullName'] {
				grid-area: fullName;
			}
			[data-control-name='email'] {
				grid-area: email;
			}
			[data-control-name='confirmEmail'] {
				grid-area: confirmEmail;
			}
			[data-control-name='displayName'] {
				grid-area: displayName;
			}
			[data-control-name='password'] {
				grid-area: password;
			}
			[data-control-name='confirmPassword'] {
				grid-area: confirmPassword;
			}

			@include responsive($breakpoint-tablet) {
				gap: 2rem;
				grid-template-columns: 1fr 1fr;
				grid-template-areas:
					'fullName displayName'
					'email password'
					'confirmEmail confirmPassword'
					'controls controls';

				.FormControl {
					max-width: unset;
				}
			}

			@include responsive($breakpoint-mobile) {
				gap: 1rem;
				grid-template-columns: 1fr;
				grid-template-areas:
					'fullName'
					'displayName'
					'email'
					'confirmEmail'
					'password'
					'confirmPassword'
					'controls';

				.FormControl {
					max-width: unset;
				}

				.controls {
					justify-content: center;
				}
			}
		}
	}

	.AccountBillingDetails {
		margin-top: 3rem;
		width: 100%;
		max-width: $breakpoint-tablet;
		display: flex;
		flex-direction: column;
		gap: 3rem;

		h3 {
			font-size: $font-size-lg;
			font-family: $font-body-bold-family;
			font-weight: $font-body-bold-weight;
		}
		.ManageBillingAddress {
			min-width: 300px;
			max-width: 400px;

			.BillingDetails form {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				gap: 3rem;
			}
		}
		.ManagePaymentMethod {
			> .Button:last-child {
				margin-top: $spacing-md;
			}
		}
		.UserSubscription {
			display: grid;
			column-gap: 3rem;
			margin-bottom: 3rem;

			&:last-child {
				margin-bottom: 0;
			}
			row-gap: $spacing-xxs;
			grid-template-columns: auto 1fr;
			grid-template-areas:
				'name controls'
				'validity controls';

			> .name {
				grid-area: name;
			}
			> .validity {
				grid-area: validity;
				font-size: $font-size-sm;

				.valid-from {
					&:before {
						content: 'Started: ';
					}
				}

				.valid-to-expires {
					&:before {
						content: ' | Expiring: ';
					}
				}
				.valid-to-renews {
					&:before {
						content: ' | Renewing: ';
					}
				}
			}
		}

		.controls {
			grid-area: controls;
			display: flex;
			align-items: center;
			justify-self: right;

			> button {
				padding: 0 $spacing-sm;
				border-left: 1px solid $color-primary;

				&:first-child {
					border-left: none;
				}
			}
		}

		.PaymentMethod {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 3rem;
			justify-content: space-between;
			margin: $spacing-md 0;

			> .details {
				display: grid;
				column-gap: $spacing-md;
				row-gap: 0;
				grid-template-columns: auto 1fr;
				grid-template-areas: 'icon card' 'icon expiry';

				> .icon {
					grid-area: icon;
					align-self: center;
				}

				> .card {
					grid-area: card;
					font-weight: $font-body-bold-weight;
					font-size: $font-size-md;
				}

				> .expiry {
					grid-area: expiry;
				}
			}
		}

		.PreviousPayments {
			background: $color-secondary;
			padding: $spacing-md;
			> div {
				width: 100%;
				display: grid;
				grid-template-columns: auto 1fr auto auto;
				grid-template-rows: 1fr;
				gap: $spacing-lg;
				margin: $spacing-md 0;

				.controls {
					grid-area: unset;
					align-self: flex-start;

					> a {
						margin-left: $spacing-md;
						display: flex;
						align-items: center;
						gap: $spacing-xs;
						justify-content: center;
					}
				}

				@include responsive($breakpoint-mobile) {
					display: block;
					.date {
						font-weight: bold;
					}
					.controls {
						justify-content: flex-end;
					}
				}
			}
		}

		@include responsive($breakpoint-desktop) {
			grid-template-columns: 1fr;
			grid-template-areas:
				'subscription'
				'billingAddress'
				'paymentMethod'
				'previousPayments';
		}
	}

	.SavedForLater {
		margin-top: 3rem;

		.saved-list {
			width: 100%;
			max-width: $breakpoint-tablet;
			margin: 0 auto;
			background: $color-secondary;
			padding: $spacing-md;

			> div {
				margin: $spacing-md 0;
				gap: $spacing-md;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;

				&.pinned > .tabler-icon-star-filled {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translate(-150%, -50%);
				}

				> a {
					padding: $spacing-md;
					flex-grow: 1;
					display: flex;

					> .title {
						flex-grow: 1;
					}

					> .already-read,
					> .already-downloaded {
						font-size: $font-size-sm;
						display: flex;
						align-items: center;
						gap: $spacing-xs;
					}
				}

				&.read,
				&.downloaded {
					a {
						background: rgba($color-brand-1, 0.5);
					}
				}
			}
		}
	}
}
