.about-program-page {
	--mask-width: 200px;
	--vertical-padding: #{$page-spacing * 3};
	--horizontal-padding: #{$page-spacing * 2};
	--padding-multiplier: 2;

	h2 {
		padding-top: 0;
		border-top: none;
	}

	section {
		padding: 0;
		h2 {
			margin-top: 0;
		}
	}

	.masked-section {
		@include wave-mask-section;
		@include set-wave-image-masks;

		grid-template-columns: calc(100% - var(--mask-width)) auto;

		.mask-container {
			width: var(--mask-width);
			@include wave-mask-container;
		}
	}

	.content-container {
		padding: var(--vertical-padding) calc(var(--horizontal-padding) * var(--padding-multiplier))
			var(--vertical-padding) var(--horizontal-padding);
	}

	.back-to-top {
		font-weight: 600;
		font-size: 0.8rem;
	}

	.landing-section {
		min-height: calc(100dvh - var(--navbar-height_approx));
		display: flex;
		flex-direction: column;
		padding: calc(var(--vertical-padding) * 0.75) var(--horizontal-padding);

		h1 {
			flex-grow: 1;
			margin-top: 0;
			align-content: end;
		}

		.subtitle {
			font-family: $font-display-bold-family;
			font-weight: $font-display-bold-weight;
			letter-spacing: $font-body-bold-spacing;

			font-size: 1.5rem;
			margin: 0 0 1.5em;
		}

		ul {
			margin: 0;
			list-style-type: none;
			padding: 0;

			li {
				font-size: 0.95rem;
				margin-bottom: 1.5em;
				&:last-of-type {
					margin-bottom: 0;
				}
			}

			a {
				padding-bottom: 0.5em;
			}
		}
	}

	.testimonials {
		&.dark-bg {
			.testimonial-container .carousel-btn {
				color: $color-text-light;
			}
		}
		.testimonial-container {
			margin: calc(var(--vertical-padding) * 0.5) 0 calc(var(--vertical-padding) * 0.25);
		}
	}

	@include responsive($masked-section-breakpoint) {
		--mask-width: 30dvw;
		.masked-section {
			position: relative;
			display: block;
		}
		.content-container {
			z-index: 2;
			position: relative;
			padding: var(--vertical-padding) var(--horizontal-padding);
		}
		.mask-container {
			opacity: 0.15;
			position: absolute;
			height: 100%;
			right: 0;
			top: 0;
		}
	}

	@include responsive($breakpoint-mobile) {
		--mask-width: 100%;
		--vertical-padding: #{$page-spacing * 2};
		--horizontal-padding: #{$page-spacing};
		.mask-container {
			opacity: 0.1;
			mask-image: unset !important;
		}
		.dark-bg .mask-container {
			opacity: 0.05;
		}
	}
}
