// --------------------------------
// Overall layout
// --------------------------------

.page-find_essentials {
	padding: calc(var(--navbar-height_approx) + #{$page-spacing * 2}) $page-spacing * 2 $page-spacing * 2;
	min-height: 100dvh;
}

.FindEssentials {
	height: 100%;
	background-attachment: fixed;

	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: 1.5rem 1fr;
	grid-template-areas: 'back back' 'answers breadcrumbs';
	row-gap: $page-spacing;
	column-gap: $page-spacing * 2;

	.find-essentials-controls {
		grid-area: back;
	}
	.find-essentials-content {
		grid-area: answers;
	}
	.find-essentials-sidebar {
		grid-area: breadcrumbs;
	}
}

// --------------------------------
// Back button
// --------------------------------

.find-essentials-controls {
	font-size: 0.8rem;
	button {
		--hover-color: #{$color-blue-light};
		--text-hover-color: #{$color-blue-light};
		@include active-state {
			@include animate-pulse;
		}
	}
}

// --------------------------------
// Answers
// --------------------------------

.find-essentials-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: $page-spacing * 1.5;

	h2 {
		&:first-letter {
			text-transform: uppercase;
		}
	}
	a {
		font-size: 1.1rem;
		line-height: 1.75em;
	}

	@include responsive($breakpoint-tablet, min) {
		@include responsive(56rem, max, height) {
			row-gap: $page-spacing;
		}
		@include responsive(50rem, max, height) {
			row-gap: $page-spacing * 0.75;
			h2 {
				margin-bottom: 0.75rem;
			}
		}
	}
}

// --------------------------------
// Breadcrumbs
// --------------------------------

.find-essentials-sidebar {
	padding: 0;
	margin: 0;
	margin-top: 1.25rem;

	text-align: right;
	font-size: 0.7rem;

	list-style: none;
	counter-reset: item;

	li {
		position: relative;
		counter-increment: item;

		margin-bottom: 1.15em;
		color: $color-blue-light;

		// Adding the list items to the end
		&::after {
			content: counter(item);
			margin-left: 1em;
			min-width: 0.8em;
			display: inline-block;
		}

		&.highlighted {
			font-weight: 600;
			color: $color-text-light;
		}
	}
}

// --------------------------------
// Tablet / Mobile mode
// --------------------------------

@include responsive($breakpoint-tablet) {
	.FindEssentials {
		grid-template-columns: 100%;
		grid-template-rows: 1fr auto;
		grid-template-areas: 'answers' 'back';
		grid-row-gap: $page-spacing * 3;
	}

	.page-find_essentials {
		padding: calc(var(--navbar-height_approx) + #{$page-spacing * 2}) $page-spacing * 2 $page-spacing * 2;
		min-height: 100dvh;
	}

	.find-essentials-controls {
		button {
			margin: 0 auto;
			font-size: 0.8rem;
		}
	}

	.find-essentials-sidebar {
		display: none;
	}

	.find-essentials-content {
		text-align: center;
		align-items: center;

		align-self: center;
		a {
			border: 2px solid $color-text-light;
			background: $color-primary;
			padding: 1.5em 2em;
			width: 100%;
			max-width: 45rem;
			&:after {
				content: unset;
			}
			@include active-state {
				background-color: transparentize($color-text-light, 0.8);
			}
		}
	}
}

@include responsive($breakpoint-mobile) {
	.page-find_essentials {
		padding: calc(var(--navbar-height_approx) + #{$page-spacing}* 1.5) $page-spacing $page-spacing * 2;
	}
	.find-essentials-content {
		row-gap: $page-spacing;
		a {
			font-size: 0.9rem;
		}
	}
}
