#footer-container {
	padding: 0;
}

.endcap {
	text-align: center;
	padding: $page-spacing * 0.5 $page-spacing;
	img {
		width: auto;
		height: auto;
		max-height: 50px;
		margin: 0 $page-spacing * 0.5;
	}
}

footer {
	padding: 120px 0 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
	background-color: $color-light-blue !important;

	@include responsive($breakpoint-tablet) {
		padding-top: 71px;
	}

	.logo-possums {
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%) translateY(-50%);
		width: 69px;
		height: 69px;
	}

	.footer-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		overflow: hidden;
		.decoration {
			position: absolute;
			bottom: -100px;
			left: 64%;
			pointer-events: none;
			width: auto;
			height: auto;

			@include responsive($breakpoint-tablet) {
				top: 10.9%;
				right: -268px;
				left: unset;
				bottom: unset;
				transform: scale(0.59);
			}
		}
	}

	@include responsive($breakpoint-mobile) {
		padding: 120px 16px 50px;
	}

	.footer-content {
		display: flex;
		gap: 60px;
		max-width: 1060px;
		width: 100%;
		justify-content: space-between;
		z-index: 1;

		@include responsive($breakpoint-tablet) {
			flex-direction: column-reverse;
			align-items: center;
			padding-left: 22px;
			padding-right: 22px;
			gap: 248px;
			max-width: 498px;
		}

		.footer-links {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			column-gap: 30px;
			row-gap: 67px;
			width: 389px;

			@include responsive($breakpoint-tablet) {
				width: 100%;
				gap: 42px;
			}
	
			.footer-link__group {
				display: flex;
				flex-direction: column;
				gap: 23px;
	
				.footer-link__group-title {
					font-weight: 600;
					font-size: 13px;
					text-decoration: underline;
					margin: 0;
					text-transform: uppercase;
				}
	
				.footer-link__group-content {
					display: flex;
					flex-direction: column;
					gap: 10px;

					@include responsive($breakpoint-tablet) {
						gap: 30px;
					}
	
					a {
						font-weight: 500;
						font-size: 14px;
					}
				}
			}
		}

		.subscribe {
			display: flex;
			flex-direction: column;
			gap: 20px;
			max-width: 498px;

			p,
			h3 {
				margin: 0;
			}

			.cta {
				display: flex;

				@include responsive($breakpoint-mobile) {
					display: block;
				}

				label {
					display: none;
				}

				input {
					height: 100%;
					flex-grow: 1;
					padding: 0 1em;
					border-width: 1px;
					border-right-width: 0px;
					height: 60px;
					background-color: white;
					font-size: 14px;
					line-height: 22px;
					letter-spacing: 0;

					@include responsive($breakpoint-mobile) {
						border-right-width: 1px;
						width: 100%;
					}
				}

				button {
					height: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 25px;

					@include responsive($breakpoint-mobile) {
						width: 100%;
					}
				}
			}
		}
	}

	.drpam-logo { 
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 120px;

		@include responsive($breakpoint-mobile) {
			margin-top: 25px;
		}
		
		img {
			height: 60px;
			width: auto;
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 5px;
			margin-top: 5px;
		}
	}
}
