#quiz-modal {
    .content:has(.quiz-form-container.with-background) {
        background: url('/images/backgrounds/quiz-background.webp');
        background-size: cover;
        background-position: 100%;
    }

    .inner {
        border-radius: 15px;
        min-width: 897px;
        max-width: 897px;
        min-height: 475px;

        @include responsive($breakpoint-mobile) {
            border-radius: 0;
            width: 100%;
            height: 100%;
            max-width: unset;
            min-width: unset;
            top: 50%;
            max-height: 100%;
        }

        .content {
            border: none;
            padding: 8px;
        }
    }

    .quiz-form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .quiz-header {
            display: flex;
            width: 100%;
            position: relative;

            @include responsive($breakpoint-mobile) {
                flex-direction: column;
                gap: 28px;
            }
        }

        .title {
            font-size: 35px;
            font-weight: 700;
            line-height: 45px;
            text-align: center;
            margin: 15px 0 8px;
        }

        .quiz-age-container {
            max-width: 350px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            @include responsive($breakpoint-mobile) {
                max-width: unset;
                padding: 0 32px;
            }
        }

        .quiz-challenge-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            @include responsive($breakpoint-mobile) {
                grid-template-columns: repeat(1, 1fr);
                padding: 0 32px;
            }
            gap: 15px;
            padding: 0 44px;
        }

        .quiz-age-container,
        .quiz-challenge-container {
            button, a {
                text-transform: uppercase;
                width: 100%;
                border: solid 1px $color-blue-dark;
                height: 60px;
                padding: 0 23px;

                p {
                    margin: 0;
                    font-size: 13px;
                    letter-spacing: 0.28px;
                    font-weight: 600;
                    text-align: center;
                }

                &.danger {
                    background-color: #ED2D2D99;
                    border: solid 1px #ED2D2D;
                    color: white;

                    &:hover {
                        transform: unset;
                        background-color: #ED2D2D;
                    }
                }
            }

            a:hover {
                opacity: unset;
            }

            .item:last-child:nth-child(odd) {
                grid-column: span 2;
                justify-self: center;
                width: 50%;
                margin-left: auto;
                margin-right: auto;

                @include responsive($breakpoint-mobile) {
                    grid-column: unset;
                    width: 100%;
                }
            }
        }
    }

    .controller {
        cursor: pointer;

        &.invisible {
            visibility: hidden;
        }
    }

    .breadcrumbs {
        display: flex;
        gap: 25px;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        justify-content: center;
        pointer-events: none;

        @include responsive($breakpoint-mobile) {
            position: unset;
        }

        .item {
            border-bottom: 2px solid $color-blue-dark;
            opacity: 0.72;
            text-transform: uppercase;
            width: 147px;
            text-align: center;
            font-size: 13px;
            font-weight: 600;
            padding: 5px 0;
            cursor: normal;

            &.active {
                opacity: 1;
            }

            @include responsive($breakpoint-mobile) {
                width: unset;
                flex: 1;
            }
        }
    }
}