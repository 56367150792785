.article-list-container {
	$mobile-subtext-color: $color-blue-mid;
	$mobile-subtext-size: 0.8rem;

	padding: $page-spacing $page-spacing * 2;
	max-width: 1200px;
	margin: 0 auto;

	&.hide-first-column {
		@include responsive($article-list-breakpoint, min) {
			.results-list li {
				grid-template-columns: 3rem 1fr auto;
				grid-template-areas: 'info title button';
				padding-left: 1rem;
			}
		}
	}

	// The pop-up styles are in components > welcome-popup.scss
	.results-list {
		margin: 0;
		padding: 0;
		list-style-type: none;

		--column-gap: #{$page-spacing * 0.75};

		// General top level styles for the element containers
		li {
			display: grid;
			grid-template-columns: 2rem 3rem 1fr auto;
			grid-template-areas: 'flag info title button';
			column-gap: var(--column-gap);
			align-items: center;
			margin-bottom: $page-spacing * 1.5;

			@include responsive($article-list-breakpoint, min) {
				&.read,
				&.pinned {
					background: transparentize($color-primary, 0.95);
				}

				&.pinned:not(.read) {
					min-height: 6rem; // To make room for the essential label
				}
			}
			@include responsive($article-list-breakpoint) {
				&.read {
					background: transparentize($color-primary, 0.95);
				}
			}
		}

		.essential-elements {
			grid-area: flag;
		}
		.reading-time,
		.resource-type,
		.already-read {
			grid-area: info;
		}
		.article-title {
			grid-area: title;
		}
		.actions {
			grid-area: button;
		}

		// Column 1 - the essential star, essential label
		li:not(.read) {
			@include responsive($article-list-breakpoint, min) {
				.essential-elements {
					background-color: $color-primary;
				}
			}
			.essential-label {
				display: block;
			}
		}
		.essential-elements {
			display: flex;
			align-items: center;

			svg {
				--icon-size: 1.25rem;
				height: var(--icon-size);
				width: var(--icon-size);
			}

			@include responsive($article-list-breakpoint, min) {
				position: relative;
				justify-content: center;
				align-self: stretch;

				svg {
					position: absolute;
					right: calc(100% + #{$page-spacing * 0.5});
					top: calc(50% - (var(--icon-size) / 2));
				}
				.essential-label {
					display: none; // There is other CSS that makes it show if the article hasn't been read

					color: $color-text-light;
					transform: rotate(-90deg);
					font-size: 0.75rem; // If you change this font-size, check the min-height on the li.pinned min-height parameter
					line-height: 1em;
					text-transform: lowercase;

					.extra-label {
						display: none;
					}
				}
			}
			@include responsive($article-list-breakpoint) {
				color: $mobile-subtext-color;
				font-size: $mobile-subtext-size;
				svg {
					--icon-size: 1rem;
					margin-right: 0.5em;
				}
				.essential-label {
					line-height: 1em;
				}
			}
		}

		// Column 2 - reading time / already read / resource type label
		li.read {
			.reading-time {
				display: none;
			}
		}
		@include responsive($article-list-breakpoint, min) {
			.already-read,
			.reading-time {
				text-align: center;
				.time-subtitle,
				.already-read-text {
					font-size: 0.65rem;
					line-height: 1.2em;
				}
			}
			.reading-time {
				.minutes {
					display: block;
					font-family: $font-display-bold-family;
					font-weight: $font-display-bold-weight;

					font-size: 2rem;
					line-height: 1;
					margin-bottom: 0.3rem;
				}
				.time-subtitle {
					margin-top: 0;
				}
			}
			.already-read {
				position: relative;
				svg {
					position: absolute;
					right: calc(100% + var(--column-gap));
				}
			}
			.resource-type {
				line-height: 1em;
				text-align: center;
				.resource-label {
					display: none;
				}
			}
		}
		@include responsive($article-list-breakpoint) {
			.already-read,
			.reading-time,
			.resource-type {
				p {
					color: $mobile-subtext-color;
					font-size: $mobile-subtext-size;
					margin: 0;
				}
			}
			.already-read,
			.reading-time {
				display: flex;
				align-items: center;
				> :first-child {
					margin-right: 0.5em;
				}

				svg {
					color: $mobile-subtext-color;
				}

				br {
					display: none;
				}
			}

			.resource-type {
				svg {
					display: none;
				}
			}
		}

		// Column 3 - article title
		@include responsive($article-list-breakpoint, min) {
			li:not(.read) .article-title {
				font-size: 1.2rem;
				line-height: 1.4em;
				font-family: $font-display-bold-family;
				font-weight: $font-display-bold-weight;
			}
			.article-title {
				font-size: 0.85rem;
				padding: 1em 0;
			}
		}
		@include responsive($article-list-breakpoint) {
			li.read .article-title {
				// color: $mobile-subtext-color;
			}
			li:not(.read) {
				.article-title {
					font-size: 1.1rem;
					font-weight: 700;
					line-height: 1.5em;
				}
			}
		}
		.article-title {
			&:after {
				display: none;
			}
			@include active-state {
				color: $color-blue-mid;
			}
		}

		// Column 4 - the save for later and mark as unread buttons
		li.read .save-later-btn {
			display: none;
		}
		.actions {
			text-align: center;
			@include responsive($article-list-breakpoint, min) {
				padding-right: $page-spacing * 0.5;
				.mark-unread-btn {
					--text-hover-color: #{$color-blue-mid};
				}
			}
			.save-later-btn.mobile-only {
				line-height: 1em;
				padding: 0.4em;
				@include active-state {
					background-color: $color-primary;
					color: white;
					cursor: pointer;
				}
			}
		}
	}

	// ------------------------------------------
	// Responsive styles
	// ------------------------------------------

	// Switching over the buttons
	$button-switchover: $breakpoint-tablet;
	@include responsive($button-switchover) {
		.desktop-only {
			display: none;
		}
	}
	@include responsive($button-switchover, min) {
		.mobile-only {
			display: none;
		}
		.actions {
			width: 11rem;
		}
	}

	// Mobile styling - slightly larger than the normal breakpoint, as that is what the content needed
	@include responsive($article-list-breakpoint) {
		padding: 0;

		> h2,
		> p {
			padding: $spacing-md;
		}

		.results-list {
			li {
				grid-template-columns: auto 1fr auto;
				grid-template-areas:
					'title title button'
					'info flag button';
				margin-bottom: $page-spacing * 0.5;
				padding: $page-spacing * 0.5 $page-spacing;
				row-gap: $page-spacing * 0.4;
			}
		}
	}
}
