.ForgotPassword {
	.forgot-password-container {
		width: 100%;
		max-width: 320px;
		margin: 0 auto;
	}
	.back-to-login {
		text-align: center;
		align-self: center;
	}

	.ForgotPasswordForm {
		.Form {
			display: flex;
			flex-direction: column;
			gap: $spacing-xxxl;
			align-items: stretch;

			label {
				display: none;
			}

			input {
				color: $color-secondary;
				border-bottom: 2px solid $color-blue-mid;

				&::placeholder {
					color: $color-blue-mid;
				}
			}

			.Button[type='submit'] {
				@include set-button-color($color-secondary);
				@include set-button-type(outline);
				--text-color: $color-secondary;
			}

			.forgot {
				margin-top: $spacing-lg;
			}
		}
	}
}
