@mixin wave-mask-section {
	max-width: 100dvw;
	padding: 0;
	display: grid;
	overflow-y: hidden;
	align-items: stretch;
}

@mixin wave-mask-container {
	mask-repeat: no-repeat;
	mask-position: left;
	mask-size: 100% 100%;
	overflow: hidden;

	background-size: cover;
}

@mixin set-wave-image-masks {
	$orientations: top_heavy, bottom_heavy, straight;
	$sides: left, right;
	$sizes: small, large;

	@each $side in $sides {
		@each $orientation in $orientations {
			@each $size in $sizes {
				.mask-image-#{$side}-#{$orientation}-#{$size} {
					mask-image: url(/images/masks/wave-#{$side}-#{$orientation}-#{$size}.png);
				}
			}
		}
	}
}
