.popup {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: left;
	align-items: left;
	cursor: pointer;

	margin-bottom: $page-spacing;

	.popup-content,
	a {
		background-color: $color-primary;
		color: $color-text-light;
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		padding: $page-spacing;
		text-decoration: none;
		row-gap: $page-spacing * 0.15;

		.welcome-text,
		.welcome-title,
		.read-time {
			width: 100%;
			margin: 0;
		}

		.welcome-title {
			margin-top: 0.75em;
		}

		.welcome-text,
		.read-time {
			font-size: 0.75rem;
		}

		.popup-image {
			position: absolute;
			top: 0;
			right: 0;
			width: auto;
			height: auto;
			max-height: 100%;
			max-width: 100%;

			-webkit-mask-image: linear-gradient(to right, transparent, $color-primary 50%, $color-primary 100%);
			mask-image: linear-gradient(to right, transparent, $color-primary 50%, $color-primary 100%);

			filter: grayscale(100%);
		}

		.close-btn {
			position: absolute;
			top: 0;
			right: 0;
			background-color: transparent;
			border: none;
			font-size: 1em;
			color: $color-white;
			padding: 0.5rem;
			cursor: pointer;

			&:hover {
				color: black;
			}
		}
	}

	@include responsive($breakpoint-mobile) {
		.popup-content {
			padding: 2rem 1.5rem;

			.welcome-text,
			.welcome-title,
			.read-time {
				width: 90%;
				padding: 3px;
			}

			.welcome-title {
				font-size: large;
			}

			.popup-image {
				opacity: 0.2;
			}

			.close-btn {
				top: 5px;
				right: 5px;
			}
		}
		.popup-content,
		a {
			.popup-image {
				max-width: 100%;
			}
			.welcome-text {
				font-size: 0.8em;
			}

			.welcome-title {
				font-size: 1.2em;
				text-align: left;
				margin-right: 10px;
			}
		}
	}

	&.read {
		.popup-content {
			padding: 1em 2em;

			.welcome-text,
			.close-btn {
				display: none;
			}

			.welcome-title,
			.read-time {
				padding: 0;
			}

			.welcome-title {
				margin: 0;
				font-size: 1.2rem;

				@include responsive($breakpoint-mobile) {
				}

				@include responsive(45rem) {
					font-family: $font-body-bold-family;
					font-weight: $font-body-bold-weight;
					line-height: 1.5em;
					font-size: 1.1rem;
				}
			}
		}
	}
}
