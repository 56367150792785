.speak-to-an-ndc-professional {
	--content-max-width: 45rem;

	section {
		padding-bottom: 0;
		&:last-child {
			padding-bottom: $page-spacing * 3;
		}
		&:not(.restricted-width) {
			p {
				max-width: var(--content-max-width);
			}
		}
	}
	.ndc {
		.content-block {
			p {
				max-width: var(--content-max-width);
			}
		}
		&.dual-content-section {
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

	.sidenote {
		font-size: 0.8rem;
		font-style: italic;
		margin-bottom: 2.25em;
	}

	.one-on-one {
		.button {
			margin-top: $page-spacing;
		}
	}
}
