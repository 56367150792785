@mixin animation-duration($duration) {
	-webkit-animation-duration: $duration;
	animation-duration: $duration;
}

@mixin animation-timing($timing) {
	-webkit-animation-timing-function: $timing;
	animation-timing-function: $timing;
}

@mixin animation-iteration($iteration) {
	-webkit-animation-iteration-count: $iteration;
	animation-iteration-count: $iteration;
}

@mixin animation-name($name) {
	-webkit-animation-name: $name;
	animation-name: $name;
}

@mixin set-animation($name, $duration: 1s, $timing: ease-out, $iteration: infinite) {
	@include animation-name($name);
	@include animation-duration($duration);
	@include animation-timing($timing);
	@include animation-iteration($iteration);
}

@keyframes pulse {
	0% {
		transform: translateX(0);
	}
	30% {
		transform: translateX(0.5em);
	}
	50% {
		transform: translateX(0);
	}
	65% {
		transform: translateX(0.25em);
	}
	80% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(0);
	}
}

@mixin animate-pulse {
	@include set-animation(pulse, 1.5s, ease-out, infinite);
}


