h1,
h2,
h3,
h4,
p {
	margin: 0;
}

h1 {
	font-size: 35px;
	line-height: 45px;
	letter-spacing: 0;

	@include responsive($breakpoint-tablet) {
		font-size: 28px;
		line-height: 31px;
	}
}

h2 {
	font-size: 35px;
	line-height: 45px;
	font-weight: 700;
	letter-spacing: 0;

	@include responsive($breakpoint-tablet) {
		line-height: 32px;
	}
}

p {
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0;
}

h3 {
	font-size: 23px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 29px;
}

h4 {
	font-size: 40px;
	line-height: 68px;
	font-weight: 300;
	letter-spacing: 0;
	font-family: "Montserrat", sans-serif;
}

a:hover {
	opacity: 0.7;
}

.home-page {
	position: relative;
	section {
		width: 100%;
		overflow: hidden;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		--section-padding-top: 0;
		--section-padding-bottom: 0;
		--section-padding-left: 0;
		--section-padding-right: 0;

		padding: var(--section-padding-top) var(--section-padding-right) var(--section-padding-bottom) var(--section-padding-left);
	}

	.content-container {
		max-width: 66.5rem;
		display: flex;
		z-index: 1;

		--content-padding-top: 0;
		--content-padding-bottom: 0;
		--content-padding-left: 0;
		--content-padding-right: 0;

		padding: var(--content-padding-top) var(--content-padding-right) var(--content-padding-bottom) var(--content-padding-left);
	}

	@media (max-width: $breakpoint-tablet) {
		h2 {
			font-size: 28px;
		}
	}

	.galaxy-section {
		img.galaxy-image {
			position: absolute;
			width: auto;
			height: auto;
		}
	}

	#welcome-section {
		--section-padding-top: 95px;
		--section-padding-bottom: 107px;
		position: relative;
		overflow: hidden;
		background: url('/images/backgrounds/bg-welcome.webp');
		background-size: cover;

		#galaxy-0 {
			top: 0;
			right: 0;
		}

		#galaxy-1 {
			left: 0;
			bottom: 0;
		}

		@include responsive($breakpoint-desktop) {
			--section-padding-top: 95px;
			--section-padding-bottom: 106px;
			--section-padding-right: 0;
			--section-padding-left: 0;

			#galaxy-1 {
				left: -314px;
			}
		}

		@include responsive($breakpoint-tablet) {
			--section-padding-top: 40px;
			--section-padding-bottom: 55px;
			--section-padding-right: 44px;
			--section-padding-left: 44px;
			background-position: 25%;
		}

		.content-container {
			max-width: 705px;
			flex-direction: column;
			align-items: center;
			gap: 30px;
			@include responsive($breakpoint-mobile) {
				gap: 29px;
			}

			.description {
				padding: 0 45px;
				text-align: center;
				display: flex;
				flex-direction: column;
				gap: 25px;

				@include responsive($breakpoint-desktop) {
					margin-top: 0;
				}

				@media (max-width: $breakpoint-tablet) {
					text-align: start;
					padding: 0;
				}
			}

			.quiz-button {
				margin-top: 20px;

				@include responsive($breakpoint-desktop) {
					margin-top: 24px;
				}

				@include responsive($breakpoint-mobile) {
					width: 100%;
					margin-top: 13px;
				}
			}

			#browse-all-programs {
				letter-spacing: 1px;

				a {
					font-weight: 600;
					text-decoration: underline;
					color: inherit;
					text-transform: uppercase;
					font-size: 14px;
					line-height: 22px;
					letter-spacing: 0;
				}

				@include responsive($breakpoint-mobile) {
					margin-top: -10px;
				}
			}
		}

		#galaxy-0 {
			left: calc(65%);
		}

		@include responsive($breakpoint-desktop) {
			#galaxy-0 {
				left: calc(73%);
			}
		}

		@include responsive($breakpoint-tablet) {
			#galaxy-0 {
				transform: scaleX(-1);
				top: -26px;
				right: 70%;
				left: unset;
			}

			#galaxy-1 {
				display: none;
			}
		}

		@include responsive($breakpoint-mobile) {
			#galaxy-0 {
				transform: scale(-0.46, 0.46);
				transform-origin: top;
				right: 3%;
				left: unset;
			}
		}
	}

	#section2 {
		background-color: $color-light-blue;
		--section-padding-top: 55px;
		--section-padding-bottom: 53px;

		@include responsive($breakpoint-desktop) {
			--section-padding-bottom: 56px;
		}

		@include responsive($breakpoint-mobile) {
			--section-padding-top: 36px;
			--section-padding-bottom: 36px;
			--section-padding-right: 40px;
			--section-padding-left: 40px;
		}
	}

	.mask-section-container {
		overflow: hidden;
		display: flex;
		justify-content: center;
	}

	.masked-section {
		--mask-image-base-width: 25dvw;
		width: 1920px;
		min-width: 1920px;

		@include wave-mask-section;

		.mask-container {
			@include wave-mask-container;
		}
		@include set-wave-image-masks;

		@include responsive($breakpoint-tablet) {
			width: unset;
			min-width: unset;
			overflow: hidden;
		}

		// -----------------------------------
		// Image masking + styles
		// -----------------------------------
		@include responsive($masked-section-breakpoint, min) {
			&.mask-right {
				grid-template-columns: 1fr auto;
				.mask-container {
					width: var(--mask-image-base-width);
				}
			}
			&.mask-both {
				grid-template-columns: auto 1fr auto;
				.mask-container {
					width: var(--mask-image-base-width);
				}
			}
			&.mask-left {
				grid-template-columns: auto 1fr;
				.mask-container {
					width: var(--mask-image-base-width);
				}
			}
		}

		// -----------------------------------
		// Content
		// -----------------------------------
		.content-container {
			display: flex;
			justify-content: center;
			align-items: flex-start;

			a.link {
				font-weight: bold;
				font-size: 0.95rem;
				margin-top: 1.5em;
			}
		}
	}

	.about-possums {
		.mobile-mask-container {
			width: 100%;
			position: absolute;
			height: 238px;
			display: none;
	
			@include responsive($masked-section-breakpoint) {
				display: block;
			}
			
			.mobile-mask {
				width: 100%;
				height: 100%;
				position: absolute;
				mask-image: url("/images/masks/wave-top-right.png");
				mask-size: 100% 100%;
				background-size: cover;
				background-position: 0% 25%;
				background-size: 170%;
				background-image: url("/images/accent-photos/baby1.webp");
				z-index: 2;
			}

			img {
				width: 100%;
				height: 100%;
  				filter: drop-shadow(-5px 2px 0px #6C90A9) drop-shadow(0px 2px 0px #6C90A9);
				z-index: -1;
				object-fit: fill;
			}
		}

		.content-container {
			z-index: 3;
			align-items: flex-start;

			--content-padding-top: 101px;
			--content-padding-bottom: 109px;
			--content-padding-left: 0px;
			--content-padding-right: 59px;

			@include responsive($breakpoint-desktop) {
				--content-padding-top: 101px;
				--content-padding-bottom: 112px;
				--content-padding-left: 42px;
				--content-padding-right: 11px;
			}

			@media (max-width: $breakpoint-tablet) {
				--content-padding-top: 217px;
				--content-padding-bottom: 61px;
				--content-padding-left: 45px;
				--content-padding-right: 45px;
				text-align: start;
				flex-direction: column;
			}

			.left-section {
				max-width: 36rem;
				min-width: 36rem;

				@media (max-width: $breakpoint-tablet) {
					max-width: unset;
					min-width: unset;
				}

				h2 {
					font-weight: 700;
					font-size: 2.3333rem;
					letter-spacing: 0px;

					@media (max-width: $breakpoint-tablet) {
						font-size: 28px;
					}
				}

				.left-section__description {
					margin-top: 20px;
					display: flex;
					flex-direction: column;
					gap: 22px;

					@include responsive($breakpoint-mobile) {
						margin-top: 22px;
					}
				}
			}

			.right-section {
				margin-left: 7.8rem;

				@include responsive($breakpoint-desktop) {
					margin-left: 119px;
				}

				h3 {
					margin-top: 8px;
					margin-bottom: 19px;
					@include responsive($breakpoint-desktop) {
						margin-top: 7px;
						margin-bottom: 22px;
					}

					@include responsive($breakpoint-mobile) {
						margin-top: 0;
						margin-bottom: 24px;
					}
				}

				@media (max-width: $breakpoint-tablet) {
					margin-left: 0;
					margin-top: 48px;
				}

				.program-container {
					display: flex;
					flex-direction: column;
					gap: 6px;

					.program-item {
						background-color: $color-light-blue;
						border-radius: 1000px;
						display: flex;
						gap: 10px;
						padding: 6px 9px;
						transition: all 200ms ease;
						transform-origin: top left;
						cursor: pointer;
						align-items: center;

						p {
							color: $color-primary;
						}

						&:hover {
							transform: scale(1.05);
							box-shadow: 0px 0px 5px $color-blue-dark;
						}
					}
				}
			}
		}

		.mask-container {
			--mask-image-base-width: 31.86667rem;
			mask-size: 150% 100%;
			background-image: url("/images/accent-photos/baby1.webp");

			@include responsive(1550px) {
				mask-size: 60% 100%;
				background-position: 35%;
			}

			@include responsive($breakpoint-tablet) {
				mask-size: 100% 100%;
				background-position: 24%;
			}
		}

		#galaxy-0 {
			left: 0;
			top: 0;
		}

		@include responsive($masked-section-breakpoint, min) {
			#galaxy-1,
			#galaxy-2 {
				display: none;
			}
		}

		@include responsive($masked-section-breakpoint) {
			#galaxy-0 {
				display: none;
			}
		}

		@include responsive($breakpoint-desktop) {
			#galaxy-0 {
				left: -75%;
			}
		}

		#galaxy-2 {
			left: 0;
			top: 0 ;
		}

		#galaxy-1 {
			right: 0;
			top: 81px;
		}
	}

	.journey-section {
		--section-padding-top: 102px;
		--section-padding-bottom: 101px;

		@include responsive($breakpoint-desktop) {
			--section-padding-bottom: 103px;
		}

		overflow: hidden;
		.content-container {
			text-align: center;
			align-items: center;
			width: 100%;
			gap: 10px;

			@include responsive($breakpoint-mobile) {
				h2 {
					padding-right: 22px;
				}
			}
    
			@media (max-width: $breakpoint-tablet) {
				text-align: start;
			}

			.description {
				max-width: 40rem;
				text-align: inherit;

				@include responsive($breakpoint-desktop) {
					margin-top: 0;
				}

				@include responsive($breakpoint-mobile) {
					margin-top: 8px;
				}
			}

			.journey-card-container {
				display: flex;
				gap: 30px;

				@include responsive($breakpoint-desktop) {
					margin-top: 13px;
				}

				@media (max-width: $breakpoint-tablet) {
					flex-direction: column;
					gap: 4px;
					margin-top: 9px;
				}
			}

			.get-started {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 30px;

				@include responsive($breakpoint-desktop) {
					margin-top: 30px;
				}

				@include responsive($breakpoint-tablet) {
					margin-top: 64px;
				}

				@include responsive($breakpoint-mobile) {
					margin-top: 53px;
					width: 100%;
					.Button {
						width: 100%;
					}
				}
			}
		}

		#galaxy-0  {
			top: 0;
			right: 0;
		}

		#galaxy-1,
		#galaxy-2 {
			left: 0;
			bottom: 0;
		}

		#galaxy-3 {
			left: 75%;
			top: 30px;
		}

		@include responsive($breakpoint-desktop) {
			#galaxy-1 {
				left: -182px;
			}

			#galaxy-0 {
				right: -354px;
			}
		}

		@include responsive($breakpoint-mobile, min) {
			#galaxy-2
			{
				display: none;
			}

			#galaxy-0,
			#galaxy-1,
			#galaxy-3 {
				display: block;
			}
		}

		@include responsive($breakpoint-tablet) {
			overflow: unset;
			--section-padding-top: 49px;
			--section-padding-bottom: 68px;
			--section-padding-left: 43px;
			--section-padding-right: 43px;
			#galaxy-2,
			#galaxy-3 {
				display: block;
			}

			#galaxy-0,
			#galaxy-1 {
				display: none;
			}
		}

		@include responsive($breakpoint-tablet) {
			#galaxy-3 {
				left: unset;
				right: 12px;
				top: -50px;
			}
		}
	}

	.why-section {
		.mobile-mask-container {
			width: 100%;
			position: absolute;
			height: 240px;
			display: none;
	
			@include responsive($masked-section-breakpoint) {
				display: block;
			}

			.mobile-mask {
				width: 100%;
				height: 100%;
				position: absolute;
				mask-image: url("/images/masks/wave-top-left.png");
				mask-size: 100% 100%;
				background-size: 200%;
				background-position: 65%;
				background-image: url('/images/accent-photos/kevin-liang-xBLv_ddXr8k-unsplash.webp');
				filter: drop-shadow(3px 3px 0 black);
				z-index: 1;
			}

			img {
				width: 100%;
				height: 100%;
  				filter: drop-shadow(5px 2px 0px #6C90A9) drop-shadow(0px 2px 0px #6C90A9);
				z-index: -1;
				object-fit: fill;
			}
		}
		#galaxy-1 {
			display: none;
			position: absolute;
			right: 0;
			top: 0;

			@include responsive($masked-section-breakpoint) {
				display: block;
			}
		}

		#galaxy-0 {
			right: 0;
			bottom: 0;

			@include responsive($breakpoint-desktop) {
				right: 160px;
				bottom: -20px;
			}

			@include responsive($masked-section-breakpoint) {
				display: none;
			}
		}

		.content-container {
			--content-padding-top: 100px;
			--content-padding-bottom: 110px;
			--content-padding-left: 39px;
			--content-padding-right: 0;
			max-width: unset;
			justify-content: flex-start;

			@include responsive($breakpoint-desktop) {
				--content-padding-top: 101px;
				--content-padding-bottom: 84px;
				--content-padding-left: 14px;
				--content-padding-right: 14px;
			}

			@include responsive($breakpoint-mobile) {
				--content-padding-top: 62px;
				--content-padding-bottom: 32px;
				--content-padding-left: 42px;
				--content-padding-right: 48px;
			}

			@include responsive($masked-section-breakpoint) {
				margin-top: 220px;
			}

			.section-container {
				display: flex;
			
				@media (max-width: $breakpoint-tablet) {
					flex-direction: column;
					gap: 35px;
				}
			}

			.program-improve-icon {
				min-width: 32px;
				max-width: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.mask-container {
			--mask-image-base-width: 25.86667rem;
			background-position: 28%;
			mask-position: right;
			background-image: url('/images/accent-photos/kevin-liang-xBLv_ddXr8k-unsplash.webp');
			background-size: 346%;
			background-position: 61% -182px;

			@include responsive($breakpoint-desktop) {
				--mask-image-base-width: 35rem;
				mask-size: 40% 100%;
				background-size: 260%;
				background-position: 41% -182px;
			}

			@include responsive($breakpoint-tablet) {
				--mask-image-base-width: 25.86667rem;
				background-position: 55% -209px;
				background-size: 591%;
				mask-size: 100% 100%;
			}
		}

		.left-section {
			max-width: 550px;
			width: 100%;
			gap: 30px;

			@include responsive($breakpoint-desktop) {
				gap: 30px;
			}

			@include responsive($breakpoint-mobile) {
				gap: 20px;
			}

			h2 {
				max-width: 450px;
			}

			.description {
				display: flex;
				flex-direction: column;
				gap: 22px;
			}

			.learn-more {
				height: 60px;
				padding: 0 40px;

				@include responsive($breakpoint-desktop) {
					margin-top: 0;
				}

				@include responsive($breakpoint-mobile) {
					width: 100%;
					margin-top: 24px;
				}
			}
		}

		.right-section {
			margin-left: 108px;
			margin-top: 123px;
			width: 100%;
			gap: 26px;
			max-width: 24rem;

			@include responsive($breakpoint-desktop) {
				margin-top: 124px;
				margin-left: 109px;
				max-width: 19rem;
			}

			h3 {
				max-width: 250px;
			}

			@media (max-width: $breakpoint-tablet) {
				margin-left: 0;
				margin-top: 2rem;
				gap: 21px;
			}

			.item-container {
				gap: 17px;
				.item {
					gap: 13px;

					@include responsive($breakpoint-mobile) {
						gap: 17px;
					}
					p {
						line-height: 1.2rem;
					}
				}

				@include responsive($breakpoint-desktop) {
					gap: 18px;
				}
			}
		}

		.journal-logos {
			margin-left: 97px;
			margin-top: 66px;
			display: flex;
			flex-direction: column;
			width: 100%;

			h3 {
				margin: 25px 50px;
				@include responsive($breakpoint-desktop) {
					margin: 25px 36px;
				}

				@include responsive($breakpoint-mobile) {
					margin: 63px 4px 23px;
				}
			}

			@include responsive($breakpoint-desktop) {
				margin-top: 70px;
				h3 {
					margin-bottom: 32px;
				}
			}
				
			@media (max-width: 1600px) {
				margin-left: 7.5rem;
			}
				
			@media (max-width: 1500px) {
				margin-left: 5rem;
			}
				
			@media (max-width: 1400px) {
				margin-left: 0;
			}

			@include responsive($breakpoint-mobile) {
				margin: 0;
			}

			.journal-logo-container {
				display: flex;
				gap: 50px;
				align-items: center;
				flex-wrap: wrap;
				width: 100%;
				margin-top: 9px;

				@include responsive($breakpoint-mobile) {
					.journal-logo__container:first-child {
						border-top: 1px dashed white;
					}

					flex-direction: column;
					gap: 0;
					margin-top: 0;
				}
			}
		}
	}

	.featured-articles {
		--section-padding-top: 106px;
		--section-padding-bottom: 107px;

		@include responsive($breakpoint-desktop) {
			--section-padding-top: 111px;
			--section-padding-right: 32px;
			--section-padding-left: 32px;
		}

		@include responsive($breakpoint-mobile) {
			--section-padding-top: 87px;
			--section-padding-bottom: 76px;
			--section-padding-right: 46px;
			--section-padding-left: 46px;

			.carousel-controls {
				margin-top: 43px;
			}

		}

		.content-container {
			justify-content: flex-start;
			align-items: flex-start;
			max-width: 1050px;
			width: 100%;
			gap: 20px;
			flex-direction: column;

			@include responsive($breakpoint-desktop) {
				gap: 16px;
			}

			@include responsive($breakpoint-mobile) {
				gap: 36px;
			}
	
			.article-card {
				border-radius: 42px;
				overflow: hidden;
				transition: all 200ms ease;
				display: flex;
				flex-direction: column;
				aspect-ratio: 344/414;

				&:hover {
					opacity: 0.7
				}
				
				.featured-image {
					height: 180px;
					overflow: hidden;
					position: relative;

					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
	
					.category {
						position: absolute;
						left: 0;
						bottom: 0;
						padding: 14px 28px;
						width: 100%;
						background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.7));
						display: flex;
						align-items: center;

						p {
							text-transform: uppercase;
							font-weight: 700;
						}
					}
				}

				.content {
					padding: 14px 28px 28px;
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					justify-content: space-between;

					.title {
						h3 {
							margin: 0 0 20px;
							font-size: 23px;
						}
					}

					.read-more {
						display: flex;
						gap: 12px;
						align-items: center;

						.icon-wrapper {
							position: relative;
							width: 23px;
							height: 23px;

							.icon-normal,
							.icon-hover {
								position: absolute;
								left: 0;
								top: 0;
								transition: all 200ms ease;
							}

							.icon-hover {
								opacity: 0;
							}
						}

						p {
							text-transform: uppercase;
						}

						&:hover {
							opacity: unset;

							.icon-normal {
								opacity: 0;
							}

							.icon-hover {
								opacity: 1;
							}
						}
					}
				}
			}

			&.common-sleep-questions-container {
				margin-top: 20px;

				.common-sleep-questions {
					margin-top: 10px;
				}

				@include responsive($breakpoint-desktop) {
					margin-top: 24px;
					gap: 0;

					.common-sleep-questions {
						margin-top: 31px;
					}
				}

				@include responsive($breakpoint-mobile) {
					margin-top: 42px;
					gap: 0;

					.common-sleep-questions {
						margin-top: 33px;
					}
				}
			}
		}
	}

	.testimonials-section {
		--section-padding-top: 95px;
		--section-padding-bottom: 83px;
		background: url('/images/backgrounds/bg-testimonial.webp');
		background-position: 62% 64%;
		background-size: 110%;

		@include responsive($breakpoint-tablet) {
			--section-padding-left: 30px;
			--section-padding-right: 30px;
			background-position: 46% 90%;
			background-size: 140%;
		}

		@include responsive($breakpoint-mobile) {
			--section-padding-top: 58px;
			--section-padding-bottom: 60px;
			--section-padding-left: 0;
			--section-padding-right: 0;
			background-position: 55% 90%;
			background-size: 263%;	
		}

		.content-container {
			max-width: 1055px;
			flex-direction: column;
			width: 100%;

			$gap-space: 2rem;

			.testimonial-container {
				flex-direction: column;
				margin-top: 48px;
				text-align: start;

				@include responsive($breakpoint-mobile) {
					margin-top: 32px;
				}

				.testimonial-carousel {
					.testimonial-item {
						padding-top: 1.5rem;

						.location {
							font-weight: 700;
						}
					}
				}

				.quote-style {
					&:before {
						left: -1rem !important;
						top: -1.5rem;
						height: 3.2rem;
						width: 4.2rem;			
						background-image: url("/images/quote-light_bg-lg.png");
					}
					&:before {
						left: $quote-horizontal-spacing;
					}
					&:after {
						content: none;
					}
				}

				.carousel-controls {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: $gap-space;
					margin-top: 2.7rem;
					.carousel-button {
						background-color: transparent;
						color: $color-white;
						border: none;
						cursor: pointer;
						transition: background-color 0.3s ease;
				
						img {
							display: block;
							transition: transform 0.3s ease;
						}
				
						&:hover {
							img {
								transform: scale(1.2);
							}
						}
					}
				}
			}

			.testimonials {
				display: flex;
				gap: 90px;
				margin-top: 60px;
			}

			.quote-container {
				width: 100%;
				margin-top: 0;
				margin-bottom: $page-spacing * 1.5;
			}
			.quote-container,
			.quote-style,
			.quote-source {
				font-size: 0.9rem;
			}

			.title {
				width: 530px;

				@include responsive($breakpoint-mobile) {
					width: 100%;
					padding: 0 29px 0 47px;
				}
			}
		}
	}

	.trusted {
		--section-padding-top: 103px;
		--section-padding-bottom: 152px;
		--section-padding-right: 15px;
		--section-padding-left: 15px;
		overflow: hidden;

		#galaxy-0 {
			right: 0;
			bottom: 0;
		}
		#galaxy-1 {
			left: 0;
			top: 0;
		}
		@include responsive($breakpoint-desktop) {
			--section-padding-bottom: 150px;
			#galaxy-1 {
				display: none;
			}
			#galaxy-0 {
				right: -161px;
			}
		}
		@include responsive($breakpoint-mobile) {
			--section-padding-top: 56px;
			--section-padding-bottom: 209px;
			--section-padding-right: 18px;
			--section-padding-left: 18px;
			h3 {
				padding: 0 25px;
			}
			#galaxy-0 {
				right: -75px;
				bottom: -60px;
			}
		}
		.content-container {
			max-width: 1063px;
			width: 100%;

			@include responsive($breakpoint-mobile) {
			}
		}
		.trusted-names {
			display: flex;
			gap: 28px;
			align-items: center;
			flex-wrap: wrap;
			place-items: center;
			margin-top: 36px;

			.journal-logo {
				opacity: 1;
			}

			@include responsive(1235px) {
				grid-template-columns: repeat(5, 1fr);
			}

			@include responsive(955px) {
				grid-template-columns: repeat(3, 1fr);
			}

			@include responsive(630px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				padding: 0 8px;
				row-gap: 28px;
				column-gap: 11px;
			}
		}

		.subscriptions {
			margin-top: 95px;
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: center;

			@include responsive($breakpoint-mobile) {
				margin-top: 0;
				
				h3 {
					display: none;
				}
			}

			.container {
				margin-top: 27px;
				border-radius: 160px;
				background-color: #8F676F;
				display: flex;
				overflow: hidden;
				width: 100%;

				@include responsive(930px) {
					flex-direction: column;
					border-top-left-radius: 160px;
					border-top-right-radius: 160px;
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;
				}

				@include responsive($breakpoint-mobile) {
					margin-top: 66px;
				}

				.left-section {
					&:before {
						content: '';
						width: 100%;
						height: 100%;
						background-image: url('/images/backgrounds/breastfeeding.webp');
						position: absolute;
						left: 0;
						right: 0;
						background-repeat: no-repeat;
						opacity: 0.3;
						transition: all 0.2s ease;
						background-size: 120%;
						background-position: 82% -128px;
					}

					&:hover {
						&:before {
							opacity: 1;
						}
					}
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 48.5%;

					img {
						z-index: 1;
					}

					@include responsive(930px) {
						width: 100%;
						height: 240px;
						padding: 0 25px;
						align-items: flex-end;
						background-size: cover;
						background-position: top;

						img {
							width: 100%;
							object-fit: contain;
							bottom: 50%;
							margin-bottom: 45px;
						}
					}
				}

				.right-section {
					display: flex;
					flex-direction: column;
					gap: 16px;
					padding: 47px 0 43px 46px;
					max-width: 395px;

					@include responsive($breakpoint-desktop) {
						padding-top: 50px;
						padding-bottom: 46px;
						padding-left: 48px;
						gap: 17px;
					}

					@include responsive(930px) {
						width: 100%;
						max-width: 100%;
						padding: 30px;
					}

					@include responsive($breakpoint-mobile) {
						padding: 33px 30px;
						gap: 25px;
					}

					.title {
						font-size: 20px;
						color: white;
						line-height: 29px;
						letter-spacing: 0.04px;
						@include responsive($breakpoint-mobile) {
							font-size: 25px;
							line-height: 28px;
						}
					}

					button {
						color: #8F676F;
						height: 60px;
						margin-top: 12px;

						@include responsive(930px) {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						@include responsive($breakpoint-mobile) {
							margin-top: 0;
						}
					}
				}
			}
		}
	}
}
