@import '@dr-pam/common-components/Styles/Themes/psp';

@import 'abstracts/abstracts';

* {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}
html,
body {
	background: $color-primary;
	margin: 0;
	font-size: 15px;
	font-family: $font-body-normal-family;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.navbar + * {
	flex-grow: 1;
	flex-shrink: 0;
}

a {
	text-decoration: none;
}

h1,
h2,
h3,
h4 {
	font-weight: 600;
}

.dark-bg {
	background-color: $color-primary;
	color: $color-text-light;
	a {
		color: inherit;
	}
	.emphasised-text {
		color: $color-blue-light;
	}
}

.light-bg {
	background-color: $color-white;
	color: $color-text-dark;
	a {
		color: inherit;

		&.button:not(.outline):not(.subtle):not(.quiet):not(.inline) {
			color: var(--text-color);
		}
	}

	.emphasised-text {
		color: $color-accent-1;
	}
}

.page-container {
	margin-top: $navbar-height;
}


input:not([type='checkbox']),
select {
	&.form-input + .error {
		margin-top: unset;
	}
}

@import 'components/components';
@import 'page-layouts/page-layouts';